import React, { useEffect, useState } from 'react';
import styles from './Hero.module.css';
import style from '../LandingPage/Home.module.css'
import IconsSocial from '../Social-icons/IconsSocial';
import '../../index.css'
import { isVisible } from '@testing-library/user-event/dist/utils';
import { Howl, Howler } from 'howler';
const Hero = ({ isVisible, backFromDot }) => {
    const [animate, setAnimate] = useState(false);
    const [leaveAnimate, SetLeaveAnimate] = useState(false);
    useEffect(() => {
        // Trigger animation after the component has mounted
        setAnimate(true);
        SetLeaveAnimate(isVisible);
    }, [isVisible]);
    useEffect(() => {
        // Initialize Howler
        Howler.volume(1); // Adjust volume (0.0 to 1.0)


        // Play the sound
        const sound = new Howl({
            src: './sound.mp3',
            autoplay: true,
            loop: false,
            volume: 0.5, // Adjust volume (0.0 to 1.0)

        });
        // Clear listener after first call.
        sound.once('load', function () {
            sound.play();
        });
        sound.play();
        // Clean up function
        return () => {
            sound.unload();
        };
    }, []); // Empty dependency array ensures the effect runs only once after the initial render

    return (


        <div className='overlay'>
            <div className={styles.cont} style={{ flexDirection: 'row-reverse' }}>
                <div className={styles.finance} style={{ marginLeft: '0' }}>
                    <div className={`${styles.headline2} `}   >
                        <h1>نقدم أفضل</h1>
                    </div>
                    <div className={`${styles.headline2} ${styles.fadeInThird}`}>
                        <h2>الحلول المالية</h2>

                    </div>
                    <div className={`${styles.des2} ${styles.fadeInThird} `}>
                        <p>
                            بنكنوت شركة استشارات مالية تقدم الخدمات المالية للمشاريع الكبيرة والصغيرة، لتحقيق أهدافها المالية
                        </p>
                    </div>
                    <div className={styles.numbers2}>
                        <div className={styles.count2}>
                            <div className={`${styles.numbCont}`}>

                                <div className={styles.subnumber}>
                                    <div className={styles.subTitleYellow2} style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                                        <h1>+</h1>
                                        <h2>۱٤</h2>
                                    </div>

                                </div>
                                <div className={styles.subMainTitleWhite2} style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                                    <h2>سنة خبرة</h2>
                                </div>
                            </div>
                            <div className={`${styles.numbCont} `}>
                                <div className={styles.subnumber}>
                                    <div className={styles.subTitleYellow2} style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                                        <h1>+</h1>
                                        <h2>٥۰۰</h2>
                                    </div>

                                </div>
                                <div className={styles.subnumber}>
                                    <div className={styles.subMainTitleWhite2} style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                                        <h2>عميل</h2>
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.numbCont} `}>
                                <div className={styles.subnumber}>
                                    <div className={styles.subTitleYellow2} style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                                        <h1>+</h1>
                                        <h2>۸۰</h2>
                                    </div>
                                </div>
                                <div className={styles.subMainTitleWhite2} style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                                    <h2>شريك</h2>
                                </div></div>
                        </div>
                    </div>
                </div>
                <div className={styles.Coinbg}>
                    <img src="./hero-image.png" alt="" />
                </div>

            </div>
        </div >

    );
}

export default Hero;