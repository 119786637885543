import React from 'react'
import styles from './Landing.module.css'
function CompanyNumbers() {
    return (
        <div className="row justify-content-end " style={{ paddingRight: '1rem' }}>
            <div className="col-3 pr-5">
                <div className={styles.Num}>
                    <h1 style={{ display: 'flex', flexDirection: 'row-reverse', margin: '0' }} >
                        <span style={{ paddingLeft: '.5rem', fontFamily: 'Cairo Light' }} className={styles.YellowNum}>+</span><span className={styles.WhiteNum} style={{ fontFamily: 'Cairo Light' }}>۱٤</span>
                    </h1>
                    <h2 style={{ display: 'flex', justifyContent: 'center', fontFamily: 'Cairo Light' }}>سنة خبرة</h2>
                </div>
            </div>
            <div className="col-4">
                <div className={styles.Num}>
                    <h1 style={{ display: 'flex', flexDirection: 'row-reverse', margin: '0' }} >
                        <span style={{ paddingLeft: '.5rem', fontFamily: 'Cairo Light' }} className={styles.YellowNum}>+</span><span style={{ fontFamily: 'Cairo Light' }} className={styles.WhiteNum}>٥۰۰</span>
                    </h1>
                    <h2 style={{ display: 'flex', justifyContent: 'center', fontFamily: 'Cairo Light' }}>عميل</h2>
                </div>
            </div>
            <div className="col-3">
                <div className={styles.Num}>
                    <h1 style={{ display: 'flex', flexDirection: 'row-reverse', margin: '0' }} >
                        <span style={{ paddingLeft: '.5rem', fontFamily: 'Cairo Light' }} className={styles.YellowNum}>+</span><span style={{ fontFamily: 'Cairo Light' }} className={styles.WhiteNum}>۸۰</span>
                    </h1>
                    <h2 style={{ display: 'flex', justifyContent: 'center', fontFamily: 'Cairo Light' }}>شريك</h2>
                </div>
            </div>
        </div>
    )
}

export default CompanyNumbers