import React, { useEffect, useState } from 'react';
import styles from './FAQs.module.css';
import TextField from '@mui/material/TextField';
import Calendar from './Calendar'; // Import the Calendar component
import CalendarMob from './CalendarMob'; // Import the Calendar component
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { useLocation } from 'react-router-dom';
import Footer from '../Footer/Footer'
import BottomButton from '../Footer/BottomButton'
import ReCAPTCHA from 'react-google-recaptcha';
import { Popover, Typography, Checkbox, FormControlLabel } from '@mui/material';


function FAQFrame({ question, initialText, additionalText }) {
    const [expanded, setExpanded] = useState(false);

    const handleFrameExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <div
            className={`${styles.roundedFrame} ${expanded && styles.expandedFrame}`}
            onClick={handleFrameExpand}
        >
            <div className={styles.subFrame}>
                <p className={styles.frametext}>{question}</p>
                <div className={styles.plusButtonCont}>
                    <div className={styles.plusButton}>+</div>
                </div>
            </div>

            {expanded && <p className={styles.additionalText}>{additionalText}</p>}
        </div>

    );
}
function MobFAQFrame({ question, initialText, additionalText }) {
    const [expanded, setExpanded] = useState(false);

    const handleFrameExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <div
            className={`${styles.mobroundedFrame} ${expanded && styles.mobexpandedFrame}`}
            onClick={handleFrameExpand}
        >
            <div className={styles.mobsubFrame}>
                <p className={styles.mobframetext}>{question}</p>
                <div className={styles.mobplusButtonCont}>
                    <div className={styles.mobplusButton}>+</div>
                </div>
            </div>

            {expanded && <p className={styles.mobadditionalText}>{additionalText}</p>}
        </div>
    );
}

const Modal = ({ onClose, onSubmit }) => {
    const [selectedButton, setSelectedButton] = useState({ column: 0, index: 0 });

    const handleButtonClick = (column, index) => {
        setSelectedButton({ column, index });
    };

    const isButtonSelected = (column, index) => {
        return selectedButton && selectedButton.column === column && selectedButton.index === index;
    };
    let now = new Date();
    const [selectedDate, setSelectedDate] = useState(new Date()); // Initial date

    const handleDateSelection = (date) => {
        setSelectedDate(date);

    };
    const handleSubmit = () => {
        // Combine selectedDate and selected time
        // Define time slots corresponding to each button
        const timeSlots = [
            ['09:00 AM', '11:00 AM', '01:00 PM', '03:00 PM', '05:00 PM'],
            ['10:00 AM', '12:00 PM', '02:00 PM', '04:00 PM', '06:00 PM']
        ];

        // Get the selected time slot using column and index
        const selectedTimeSlot = timeSlots[selectedButton.column][selectedButton.index];

        // Combine selectedDate and selected time slot
        const selectedDateTime = `${selectedDate} ${selectedTimeSlot}`;


        // console.log('Selected Date & Time:', selectedDateTime);
        onSubmit(selectedDateTime);
        const preferredCallTimeField = document.getElementById('preferred-call-time-web');
        const preferredCallTimeLabel = document.getElementById('preferred-call-time-web-label');

        if (preferredCallTimeField && preferredCallTimeLabel) {
            preferredCallTimeField.value = selectedDateTime;

            // Save the selected button's value
            setSelectedButton(selectedButton);

            // Set data-shrink attribute to "true" and update class to reflect the new state
            preferredCallTimeLabel.setAttribute('data-shrink', 'true');
            preferredCallTimeLabel.classList.add('MuiInputLabel-shrink');

            // Replace the old class with the new class
            preferredCallTimeLabel.classList.remove('css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root');
            preferredCallTimeLabel.classList.add('css-1c2i806-MuiFormLabel-root-MuiInputLabel-root');
            preferredCallTimeLabel.classList.remove('css-mnn31-MuiFormLabel-root-MuiInputLabel-root');

        }

        onClose(); // Close the modal after submitting
    };

    const handleClose = () => {


        const preferredCallTimeField = document.getElementById('preferred-call-time-web');
        const preferredCallTimeLabel = document.getElementById('preferred-call-time-web-label');

        if (preferredCallTimeField && preferredCallTimeLabel) {
            if (preferredCallTimeField.value !== '') {

                // Set data-shrink attribute to "true" and update class to reflect the new state
                preferredCallTimeLabel.setAttribute('data-shrink', 'true');
                preferredCallTimeLabel.classList.add('MuiInputLabel-shrink');

                // Replace the old class with the new class
                preferredCallTimeLabel.classList.remove('css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root');
                preferredCallTimeLabel.classList.add('css-1c2i806-MuiFormLabel-root-MuiInputLabel-root');
            }
        }

        onClose(); // Close the modal after submitting
    };
    return (
        <div className={styles.modaloverlay}  >
            <div className={styles.modal}>

                <div style={{ width: `100%`, height: `auto`, alignItems: `center`, display: `flex` }}>  <h2 style={{ color: '#B9BBBE', fontSize: 26, fontFamily: 'Quicksand', fontWeight: '500', wordWrap: 'break-word' }}>Select Date & Time  </h2></div>
                <div style={{ width: `100%`, display: `flex`, flexDirection: `row`, gap: `9%`, justifyContent: `center` }}>
                    <div className={styles.Calendar} style={{}}>
                        <Calendar year={now.getFullYear()} month={now.getMonth() + 1} day={now.getDate()} onSelectDate={handleDateSelection} />
                    </div>
                    <div className={styles.Calendar} style={{ flexDirection: 'row', width: `40%`, gap: `1rem` }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: `1rem` }}>
                            <button
                                className={isButtonSelected(0, 0) ? styles.selectedButton : styles.button}
                                onClick={() => handleButtonClick(0, 0)}
                            >
                                09:00 AM
                            </button>
                            <button
                                className={isButtonSelected(0, 1) ? styles.selectedButton : styles.button}
                                onClick={() => handleButtonClick(0, 1)}
                            >
                                11:00 AM
                            </button>
                            <button
                                className={isButtonSelected(0, 2) ? styles.selectedButton : styles.button}
                                onClick={() => handleButtonClick(0, 2)}
                            >
                                01:00 PM
                            </button>
                            <button
                                className={isButtonSelected(0, 3) ? styles.selectedButton : styles.button}
                                onClick={() => handleButtonClick(0, 3)}
                            >
                                03:00 PM
                            </button>
                            <button
                                className={isButtonSelected(0, 4) ? styles.selectedButton : styles.button}
                                onClick={() => handleButtonClick(0, 4)}
                            >
                                05:00 PM
                            </button>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: `1rem`, paddingTop: '1rem', paddingBottom: '1rem' }}>
                            <button
                                className={isButtonSelected(1, 0) ? styles.selectedButton : styles.button}
                                onClick={() => handleButtonClick(1, 0)}
                            >
                                10:00 AM
                            </button>
                            <button
                                className={isButtonSelected(1, 1) ? styles.selectedButton : styles.button}
                                onClick={() => handleButtonClick(1, 1)}
                            >
                                12:00 PM
                            </button>
                            <button
                                className={isButtonSelected(1, 2) ? styles.selectedButton : styles.button}
                                onClick={() => handleButtonClick(1, 2)}
                            >
                                02:00 PM
                            </button>
                            <button
                                className={isButtonSelected(1, 3) ? styles.selectedButton : styles.button}
                                onClick={() => handleButtonClick(1, 3)}
                            >
                                04:00 PM
                            </button>
                            <button
                                className={isButtonSelected(1, 4) ? styles.selectedButton : styles.button}
                                onClick={() => handleButtonClick(1, 4)}
                            >
                                06:00 PM
                            </button>
                        </div>
                    </div>
                </div>
                <div style={{ display: `flex`, flexDirection: `row`, height: `15%`, width: `100%`, alignItems: `end`, justifyContent: `end`, gap: `5%` }}>

                    <div style={{ width: `20%`, height: `60%` }}>
                        <button className={styles.Canceldate} onClick={handleClose}>Cancel</button>
                    </div>
                    <div style={{ width: `20%`, height: `60%` }}>
                        <button className={styles.Submitdate} onClick={handleSubmit}>Submit</button>
                    </div>
                </div>

            </div>
        </div >
    );
};
const MobModal = ({ onClose, onSubmit }) => {
    const [selectedButton, setSelectedButton] = useState({ column: 0, index: 0 });

    const handleButtonClick = (column, index) => {
        setSelectedButton({ column, index });
    };

    const isButtonSelected = (column, index) => {
        return selectedButton && selectedButton.column === column && selectedButton.index === index;
    };
    let now = new Date();
    const [selectedDate, setSelectedDate] = useState(new Date()); // Initial date

    const handleDateSelection = (date) => {
        setSelectedDate(date);

    };
    const handleSubmit = () => {
        // Combine selectedDate and selected time
        // Define time slots corresponding to each button
        const timeSlots = [
            ['09:00 AM', '11:00 AM', '01:00 PM', '03:00 PM', '05:00 PM'],
            ['10:00 AM', '12:00 PM', '02:00 PM', '04:00 PM', '06:00 PM']
        ];

        // Get the selected time slot using column and index
        const selectedTimeSlot = timeSlots[selectedButton.column][selectedButton.index];

        // Combine selectedDate and selected time slot
        const selectedDateTime = `${selectedDate} ${selectedTimeSlot}`;
        onSubmit(selectedDateTime);

        // console.log('Selected Date & Time:', selectedDateTime);

        const preferredCallTimeField = document.getElementById('preferred-call-time');
        const preferredCallTimeLabel = document.getElementById('preferred-call-time-label');

        if (preferredCallTimeField && preferredCallTimeLabel) {
            preferredCallTimeField.value = selectedDateTime;

            // Save the selected button's value
            setSelectedButton(selectedButton);

            // Set data-shrink attribute to "true" and update class to reflect the new state
            preferredCallTimeLabel.setAttribute('data-shrink', 'true');
            preferredCallTimeLabel.classList.add('MuiInputLabel-shrink');

            // Replace the old class with the new class
            preferredCallTimeLabel.classList.remove('css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root');
            preferredCallTimeLabel.classList.add('css-1c2i806-MuiFormLabel-root-MuiInputLabel-root');
        }

        onClose(); // Close the modal after submitting
    };

    const handleClose = () => {


        const preferredCallTimeField = document.getElementById('preferred-call-time');
        const preferredCallTimeLabel = document.getElementById('preferred-call-time-label');

        if (preferredCallTimeField && preferredCallTimeLabel) {
            if (preferredCallTimeField.value !== '') {

                // Set data-shrink attribute to "true" and update class to reflect the new state
                preferredCallTimeLabel.setAttribute('data-shrink', 'true');
                preferredCallTimeLabel.classList.add('MuiInputLabel-shrink');

                // Replace the old class with the new class
                preferredCallTimeLabel.classList.remove('css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root');
                preferredCallTimeLabel.classList.add('css-1c2i806-MuiFormLabel-root-MuiInputLabel-root');
            }
        }

        onClose(); // Close the modal after submitting
    };
    const [showFirstSet, setShowFirstSet] = useState(true);
    const [showSecondSet, setShowSecondSet] = useState(false);

    const handleNextButtonClick = () => {
        setShowFirstSet(false);
        setShowSecondSet(true);
    };

    const handleCancelButtonClick = () => {
        // Reset to initial state
        setShowFirstSet(true);
        setShowSecondSet(false);
    };

    const handleSubmitButtonClick = () => {
        // Handle submit action
    };

    const handleBackButtonClick = () => {
        setShowFirstSet(true);
        setShowSecondSet(false);
    };
    return (
        <div className={styles.modaloverlay}  >
            <div className={styles.modalmob}>

                <div style={{ width: `100%`, height: `auto`, alignItems: `center`, display: `flex`, marginLeft: '20px' }}>
                    <h2 style={{ color: '#B9BBBE', fontSize: 22, fontFamily: 'Quicksand', fontWeight: '500', wordWrap: 'break-word' }}>Select Date & Time
                    </h2>
                </div>
                <div style={{ width: `100%`, height: '100%', display: `flex`, flexDirection: `row`, gap: `9%`, justifyContent: `center` }}>
                    {showFirstSet && (
                        <div>
                            <div className={styles.Calendarmob}>
                                <CalendarMob year={now.getFullYear()} month={now.getMonth() + 1} day={now.getDate()} onSelectDate={handleDateSelection} />
                            </div>
                            <div style={{ height: '30%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <div style={{ display: `flex`, flexDirection: `row`, justifyContent: `center`, gap: `10%`, alignItems: 'center', width: '100%' }}>
                                    <button className={styles.Canceldatemob} onClick={handleClose}>Cancel</button>
                                    <button className={styles.Submitdatemob} onClick={handleNextButtonClick}>Next</button>
                                </div>
                            </div>

                        </div>
                    )}
                    {showSecondSet && (
                        <div>
                            <div className={styles.Calendarmob}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: `1rem` }}>
                                    <button
                                        className={isButtonSelected(0, 0) ? styles.selectedButton : styles.button}
                                        onClick={() => handleButtonClick(0, 0)}
                                    >
                                        09:00 AM
                                    </button>
                                    <button
                                        className={isButtonSelected(0, 1) ? styles.selectedButton : styles.button}
                                        onClick={() => handleButtonClick(0, 1)}
                                    >
                                        11:00 AM
                                    </button>
                                    <button
                                        className={isButtonSelected(0, 2) ? styles.selectedButton : styles.button}
                                        onClick={() => handleButtonClick(0, 2)}
                                    >
                                        01:00 PM
                                    </button>
                                    <button
                                        className={isButtonSelected(0, 3) ? styles.selectedButton : styles.button}
                                        onClick={() => handleButtonClick(0, 3)}
                                    >
                                        03:00 PM
                                    </button>
                                    <button
                                        className={isButtonSelected(0, 4) ? styles.selectedButton : styles.button}
                                        onClick={() => handleButtonClick(0, 4)}
                                    >
                                        05:00 PM
                                    </button>

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: `1rem` }}>
                                    <button
                                        className={isButtonSelected(1, 0) ? styles.selectedButton : styles.button}
                                        onClick={() => handleButtonClick(1, 0)}
                                    >
                                        10:00 AM
                                    </button>
                                    <button
                                        className={isButtonSelected(1, 1) ? styles.selectedButton : styles.button}
                                        onClick={() => handleButtonClick(1, 1)}
                                    >
                                        12:00 PM
                                    </button>
                                    <button
                                        className={isButtonSelected(1, 2) ? styles.selectedButton : styles.button}
                                        onClick={() => handleButtonClick(1, 2)}
                                    >
                                        02:00 PM
                                    </button>
                                    <button
                                        className={isButtonSelected(1, 3) ? styles.selectedButton : styles.button}
                                        onClick={() => handleButtonClick(1, 3)}
                                    >
                                        04:00 PM
                                    </button>
                                    <button
                                        className={isButtonSelected(1, 4) ? styles.selectedButton : styles.button}
                                        onClick={() => handleButtonClick(1, 4)}
                                    >
                                        06:00 PM
                                    </button>
                                </div>
                            </div>
                            <div style={{ height: '30%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <div style={{ display: `flex`, flexDirection: `row`, justifyContent: `center`, gap: `10%`, alignItems: 'center', width: '100%' }}>                                <button className={styles.Canceldatemob} onClick={handleBackButtonClick}>Back</button>
                                    <button className={styles.Submitdatemob} onClick={handleSubmit}>Submit</button>
                                </div>
                            </div>                                </div>

                    )}
                </div>
                {/* <div style={{ display: `flex`, flexDirection: `row`, height: `15%`, width: `100%`, justifyContent: `end`, gap: `5%`, marginTop: '.5rem' }}>

                    <div style={{ width: `6rem`, height: `2.5rem` }}>
                        <button className={styles.Canceldatemob} onClick={handleClose}>Cancel</button>
                    </div>
                    <div style={{ width: `6rem`, height: `2.5rem` }}>
                        <button className={styles.Canceldatemob} onClick={handleSubmit}>Back</button>
                    </div>
                    <div style={{ width: `6rem`, height: `2.5rem` }}>
                        <button className={styles.Submitdatemob} onClick={handleSubmit}>Next</button>
                    </div>
                    <div style={{ width: `6rem`, height: `2.5rem` }}>
                        <button className={styles.Submitdatemob} onClick={handleSubmit}>Submit</button>
                    </div>
                </div> */}

            </div>
        </div >
    );
};

function FAQs() {
    const [inputValue, setInputValue] = useState('');

    const countriesList = [
        {
            "english_name": "Egypt",
            "arabic_name": "مصر",
            "alpha2_code": "EG",
            "alpha3_code": "EGY",
            "phone_code": "20"
        },
        {
            "english_name": "Afghanistan",
            "arabic_name": "أفغانستان",
            "alpha2_code": "AF",
            "alpha3_code": "AFG",
            "phone_code": "93"
        },
        {
            "english_name": "Åland Islands",
            "arabic_name": "جزر أولاند",
            "alpha2_code": "AX",
            "alpha3_code": "ALA",
            "phone_code": "358"
        },
        {
            "english_name": "Albania",
            "arabic_name": "ألبانيا",
            "alpha2_code": "AL",
            "alpha3_code": "ALB",
            "phone_code": "355"
        },
        {
            "english_name": "Algeria",
            "arabic_name": "الجزائر",
            "alpha2_code": "DZ",
            "alpha3_code": "DZA",
            "phone_code": "213"
        },
        {
            "english_name": "American Samoa",
            "arabic_name": "ساموا الأمريكية",
            "alpha2_code": "AS",
            "alpha3_code": "ASM",
            "phone_code": "684"
        },
        {
            "english_name": "Andorra",
            "arabic_name": "أندورا",
            "alpha2_code": "AD",
            "alpha3_code": "AND",
            "phone_code": "376"
        },
        {
            "english_name": "Angola",
            "arabic_name": "أنغولا",
            "alpha2_code": "AO",
            "alpha3_code": "AGO",
            "phone_code": "244"
        },
        {
            "english_name": "Anguilla",
            "arabic_name": "أنغويلا",
            "alpha2_code": "AI",
            "alpha3_code": "AIA",
            "phone_code": "264"
        },
        {
            "english_name": "Antarctica",
            "arabic_name": "القارة القطبية الجنوبية",
            "alpha2_code": "AQ",
            "alpha3_code": "ATA",
            "phone_code": "672"
        },
        {
            "english_name": "Antigua and Barbuda",
            "arabic_name": "أنتيغوا وبربودا",
            "alpha2_code": "AG",
            "alpha3_code": "ATG",
            "phone_code": "268"
        },
        {
            "english_name": "Argentina",
            "arabic_name": "الأرجنتين",
            "alpha2_code": "AR",
            "alpha3_code": "ARG",
            "phone_code": "54"
        },
        {
            "english_name": "Armenia",
            "arabic_name": "أرمينيا",
            "alpha2_code": "AM",
            "alpha3_code": "ARM",
            "phone_code": "374"
        },
        {
            "english_name": "Aruba",
            "arabic_name": "أروبا",
            "alpha2_code": "AW",
            "alpha3_code": "ABW",
            "phone_code": "297"
        },
        {
            "english_name": "Australia",
            "arabic_name": "أستراليا",
            "alpha2_code": "AU",
            "alpha3_code": "AUS",
            "phone_code": "61"
        },
        {
            "english_name": "Austria",
            "arabic_name": "النمسا",
            "alpha2_code": "AT",
            "alpha3_code": "AUT",
            "phone_code": "43"
        },
        {
            "english_name": "Azerbaijan",
            "arabic_name": "أذربيجان",
            "alpha2_code": "AZ",
            "alpha3_code": "AZE",
            "phone_code": "994"
        },
        {
            "english_name": "Bahamas",
            "arabic_name": "باهاماس",
            "alpha2_code": "BS",
            "alpha3_code": "BHS",
            "phone_code": "1"
        },
        {
            "english_name": "Bahrain",
            "arabic_name": "البحرين",
            "alpha2_code": "BH",
            "alpha3_code": "BHR",
            "phone_code": "973"
        },
        {
            "english_name": "Bangladesh",
            "arabic_name": "بنغلاديش",
            "alpha2_code": "BD",
            "alpha3_code": "BGD",
            "phone_code": "880"
        },
        {
            "english_name": "Barbados",
            "arabic_name": "بربادوس",
            "alpha2_code": "BB",
            "alpha3_code": "BRB",
            "phone_code": "246"
        },
        {
            "english_name": "Belarus",
            "arabic_name": "بيلاروسيا",
            "alpha2_code": "BY",
            "alpha3_code": "BLR",
            "phone_code": "375"
        },
        {
            "english_name": "Belgium",
            "arabic_name": "بلجيكا",
            "alpha2_code": "BE",
            "alpha3_code": "BEL",
            "phone_code": "32"
        },
        {
            "english_name": "Belize",
            "arabic_name": "بليز",
            "alpha2_code": "BZ",
            "alpha3_code": "BLZ",
            "phone_code": "501"
        },
        {
            "english_name": "Benin",
            "arabic_name": "بنين",
            "alpha2_code": "BJ",
            "alpha3_code": "BEN",
            "phone_code": "229"
        },
        {
            "english_name": "Bermuda",
            "arabic_name": "برمودا",
            "alpha2_code": "BM",
            "alpha3_code": "BMU",
            "phone_code": "1"
        },
        {
            "english_name": "Bhutan",
            "arabic_name": "بوتان",
            "alpha2_code": "BT",
            "alpha3_code": "BTN",
            "phone_code": "975"
        },
        {
            "english_name": "Bolivia",
            "arabic_name": "بوليفيا",
            "alpha2_code": "BO",
            "alpha3_code": "BOL",
            "phone_code": "591"
        },
        {
            "english_name": "Bosnia and Herzegovina",
            "arabic_name": "البوسنة والهرسك",
            "alpha2_code": "BA",
            "alpha3_code": "BIH",
            "phone_code": "387"
        },
        {
            "english_name": "Botswana",
            "arabic_name": "بوتسوانا",
            "alpha2_code": "BW",
            "alpha3_code": "BWA",
            "phone_code": "267"
        },
        {
            "english_name": "Bouvet Island",
            "arabic_name": "جزيرة بوفيه",
            "alpha2_code": "BV",
            "alpha3_code": "BVT",
            "phone_code": "47"
        },
        {
            "english_name": "Brazil",
            "arabic_name": "البرازيل",
            "alpha2_code": "BR",
            "alpha3_code": "BRA",
            "phone_code": "55"
        },
        {
            "english_name": "British Indian Ocean Territory",
            "arabic_name": "إقليم المحيط الهندي البريطاني",
            "alpha2_code": "IO",
            "alpha3_code": "IOT",
            "phone_code": "246"
        },
        {
            "english_name": "British Virgin Islands",
            "arabic_name": "جزر العذراء البريطانية",
            "alpha2_code": "VG",
            "alpha3_code": "VGB",
            "phone_code": "1"
        },
        {
            "english_name": "Brunei",
            "arabic_name": "بروناي",
            "alpha2_code": "BN",
            "alpha3_code": "BRN",
            "phone_code": "673"
        },
        {
            "english_name": "Bulgaria",
            "arabic_name": "بلغاريا",
            "alpha2_code": "BG",
            "alpha3_code": "BGR",
            "phone_code": "359"
        },
        {
            "english_name": "Burkina Faso",
            "arabic_name": "بوركينا فاسو",
            "alpha2_code": "BF",
            "alpha3_code": "BFA",
            "phone_code": "226"
        },
        {
            "english_name": "Burundi",
            "arabic_name": "بوروندي",
            "alpha2_code": "BI",
            "alpha3_code": "BDI",
            "phone_code": "257"
        },
        {
            "english_name": "Cabo Verde",
            "arabic_name": "كابو فيردي",
            "alpha2_code": "CV",
            "alpha3_code": "CPV",
            "phone_code": "238"
        },
        {
            "english_name": "Cambodia",
            "arabic_name": "كمبوديا",
            "alpha2_code": "KH",
            "alpha3_code": "KHM",
            "phone_code": "855"
        },
        {
            "english_name": "Cameroon",
            "arabic_name": "الكاميرون",
            "alpha2_code": "CM",
            "alpha3_code": "CMR",
            "phone_code": "237"
        },
        {
            "english_name": "Canada",
            "arabic_name": "كندا",
            "alpha2_code": "CA",
            "alpha3_code": "CAN",
            "phone_code": "1"
        },
        {
            "english_name": "Caribbean Netherlands",
            "arabic_name": "الجزر الكاريبية الهولندية",
            "alpha2_code": "BQ",
            "alpha3_code": "BES",
            "phone_code": "599"
        },
        {
            "english_name": "Cayman Islands",
            "arabic_name": "جزر كايمان",
            "alpha2_code": "KY",
            "alpha3_code": "CYM",
            "phone_code": "1345"
        },
        {
            "english_name": "Central African Republic",
            "arabic_name": "جمهورية أفريقيا الوسطى",
            "alpha2_code": "CF",
            "alpha3_code": "CAF",
            "phone_code": "236"
        },
        {
            "english_name": "Chad",
            "arabic_name": "تشاد",
            "alpha2_code": "TD",
            "alpha3_code": "TCD",
            "phone_code": "235"
        },
        {
            "english_name": "Chile",
            "arabic_name": "تشيلي",
            "alpha2_code": "CL",
            "alpha3_code": "CHL",
            "phone_code": "56"
        },
        {
            "english_name": "China",
            "arabic_name": "الصين",
            "alpha2_code": "CN",
            "alpha3_code": "CHN",
            "phone_code": "86"
        },
        {
            "english_name": "Christmas Island",
            "arabic_name": "جزيرة الكريسماس",
            "alpha2_code": "CX",
            "alpha3_code": "CXR",
            "phone_code": "61"
        },
        {
            "english_name": "Cocos (Keeling) Islands",
            "arabic_name": "جزر كوكوس",
            "alpha2_code": "CC",
            "alpha3_code": "CCK",
            "phone_code": "61"
        },
        {
            "english_name": "Colombia",
            "arabic_name": "كولومبيا",
            "alpha2_code": "CO",
            "alpha3_code": "COL",
            "phone_code": "57"
        },
        {
            "english_name": "Comoros",
            "arabic_name": "جزر القمر",
            "alpha2_code": "KM",
            "alpha3_code": "COM",
            "phone_code": "269"
        },
        {
            "english_name": "Congo Republic",
            "arabic_name": "جمهورية الكونغو",
            "alpha2_code": "CG",
            "alpha3_code": "COG",
            "phone_code": "242"
        },
        {
            "english_name": "Cook Islands",
            "arabic_name": "جزر كوك",
            "alpha2_code": "CK",
            "alpha3_code": "COK",
            "phone_code": "682"
        },
        {
            "english_name": "Costa Rica",
            "arabic_name": "كوستاريكا",
            "alpha2_code": "CR",
            "alpha3_code": "CRI",
            "phone_code": "506"
        },
        {
            "english_name": "Croatia",
            "arabic_name": "كرواتيا",
            "alpha2_code": "HR",
            "alpha3_code": "HRV",
            "phone_code": "385"
        },
        {
            "english_name": "Cuba",
            "arabic_name": "كوبا",
            "alpha2_code": "CU",
            "alpha3_code": "CUB",
            "phone_code": "53"
        },
        {
            "english_name": "Curaçao",
            "arabic_name": "كوراساو",
            "alpha2_code": "CW",
            "alpha3_code": "CUW",
            "phone_code": "599"
        },
        {
            "english_name": "Cyprus",
            "arabic_name": "قبرص",
            "alpha2_code": "CY",
            "alpha3_code": "CYP",
            "phone_code": "357"
        },
        {
            "english_name": "Czechia",
            "arabic_name": "التشيك",
            "alpha2_code": "CZ",
            "alpha3_code": "CZE",
            "phone_code": "420"
        },
        {
            "english_name": "Denmark",
            "arabic_name": "الدنمارك",
            "alpha2_code": "DK",
            "alpha3_code": "DNK",
            "phone_code": "45"
        },
        {
            "english_name": "Djibouti",
            "arabic_name": "جيبوتي",
            "alpha2_code": "DJ",
            "alpha3_code": "DJI",
            "phone_code": "253"
        },
        {
            "english_name": "Dominica",
            "arabic_name": "دومينيكا",
            "alpha2_code": "DM",
            "alpha3_code": "DMA",
            "phone_code": "767"
        },
        {
            "english_name": "Dominican Republic",
            "arabic_name": "جمهورية الدومينيكان",
            "alpha2_code": "DO",
            "alpha3_code": "DOM",
            "phone_code": "1"
        },
        {
            "english_name": "DR Congo",
            "arabic_name": "جمهورية الكونغو الديمقراطية",
            "alpha2_code": "CD",
            "alpha3_code": "COD",
            "phone_code": "243"
        },
        {
            "english_name": "Ecuador",
            "arabic_name": "الاكوادور",
            "alpha2_code": "EC",
            "alpha3_code": "ECU",
            "phone_code": "593"
        },

        {
            "english_name": "El Salvador",
            "arabic_name": "السلفادور",
            "alpha2_code": "SV",
            "alpha3_code": "SLV",
            "phone_code": "503"
        },
        {
            "english_name": "Equatorial Guinea",
            "arabic_name": "غينيا الاستوائية",
            "alpha2_code": "GQ",
            "alpha3_code": "GNQ",
            "phone_code": "240"
        },
        {
            "english_name": "Eritrea",
            "arabic_name": "إريتريا",
            "alpha2_code": "ER",
            "alpha3_code": "ERI",
            "phone_code": "291"
        },
        {
            "english_name": "Estonia",
            "arabic_name": "إستونيا",
            "alpha2_code": "EE",
            "alpha3_code": "EST",
            "phone_code": "372"
        },
        {
            "english_name": "Eswatini",
            "arabic_name": "إسواتيني",
            "alpha2_code": "SZ",
            "alpha3_code": "SWZ",
            "phone_code": "268"
        },
        {
            "english_name": "Ethiopia",
            "arabic_name": "إثيوبيا",
            "alpha2_code": "ET",
            "alpha3_code": "ETH",
            "phone_code": "251"
        },
        {
            "english_name": "Falkland Islands",
            "arabic_name": "جزر فوكلاند",
            "alpha2_code": "FK",
            "alpha3_code": "FLK",
            "phone_code": "500"
        },
        {
            "english_name": "Faroe Islands",
            "arabic_name": "جزر فارو",
            "alpha2_code": "FO",
            "alpha3_code": "FRO",
            "phone_code": "298"
        },
        {
            "english_name": "Fiji",
            "arabic_name": "فيجي",
            "alpha2_code": "FJ",
            "alpha3_code": "FJI",
            "phone_code": "679"
        },
        {
            "english_name": "Finland",
            "arabic_name": "فنلندا",
            "alpha2_code": "FI",
            "alpha3_code": "FIN",
            "phone_code": "358"
        },
        {
            "english_name": "France",
            "arabic_name": "فرنسا",
            "alpha2_code": "FR",
            "alpha3_code": "FRA",
            "phone_code": "33"
        },
        {
            "english_name": "French Guiana",
            "arabic_name": "غويانا الفرنسية",
            "alpha2_code": "GF",
            "alpha3_code": "GUF",
            "phone_code": "594"
        },
        {
            "english_name": "French Polynesia",
            "arabic_name": "بولينزيا الفرنسية",
            "alpha2_code": "PF",
            "alpha3_code": "PYF",
            "phone_code": "689"
        },
        {
            "english_name": "French Southern Territories",
            "arabic_name": "أراض فرنسية جنوبية",
            "alpha2_code": "TF",
            "alpha3_code": "ATF",
            "phone_code": "262"
        },
        {
            "english_name": "Gabon",
            "arabic_name": "الجابون",
            "alpha2_code": "GA",
            "alpha3_code": "GAB",
            "phone_code": "241"
        },
        {
            "english_name": "Gambia",
            "arabic_name": "غامبيا",
            "alpha2_code": "GM",
            "alpha3_code": "GMB",
            "phone_code": "220"
        },
        {
            "english_name": "Georgia",
            "arabic_name": "‫جورجيا",
            "alpha2_code": "GE",
            "alpha3_code": "GEO",
            "phone_code": "995"
        },
        {
            "english_name": "Germany",
            "arabic_name": "ألمانيا",
            "alpha2_code": "DE",
            "alpha3_code": "DEU",
            "phone_code": "49"
        },
        {
            "english_name": "Ghana",
            "arabic_name": "غانا",
            "alpha2_code": "GH",
            "alpha3_code": "GHA",
            "phone_code": "233"
        },
        {
            "english_name": "Gibraltar",
            "arabic_name": "جبل طارق",
            "alpha2_code": "GI",
            "alpha3_code": "GIB",
            "phone_code": "350"
        },
        {
            "english_name": "Greece",
            "arabic_name": "اليونان",
            "alpha2_code": "GR",
            "alpha3_code": "GRC",
            "phone_code": "30"
        },
        {
            "english_name": "Greenland",
            "arabic_name": "جرينلاند",
            "alpha2_code": "GL",
            "alpha3_code": "GRL",
            "phone_code": "299"
        },
        {
            "english_name": "Grenada",
            "arabic_name": "غرينادا",
            "alpha2_code": "GD",
            "alpha3_code": "GRD",
            "phone_code": "473"
        },
        {
            "english_name": "Guadeloupe",
            "arabic_name": "غوادلوب",
            "alpha2_code": "GP",
            "alpha3_code": "GLP",
            "phone_code": "590"
        },
        {
            "english_name": "Guam",
            "arabic_name": "غوام",
            "alpha2_code": "GU",
            "alpha3_code": "GUM",
            "phone_code": "1"
        },
        {
            "english_name": "Guatemala",
            "arabic_name": "غواتيمالا",
            "alpha2_code": "GT",
            "alpha3_code": "GTM",
            "phone_code": "502"
        },
        {
            "english_name": "Guernsey",
            "arabic_name": "غيرنزي",
            "alpha2_code": "GG",
            "alpha3_code": "GGY",
            "phone_code": "44"
        },
        {
            "english_name": "Guinea",
            "arabic_name": "غينيا",
            "alpha2_code": "GN",
            "alpha3_code": "GIN",
            "phone_code": "224"
        },
        {
            "english_name": "Guinea-Bissau",
            "arabic_name": "غينيا بيساو",
            "alpha2_code": "GW",
            "alpha3_code": "GNB",
            "phone_code": "245"
        },
        {
            "english_name": "Guyana",
            "arabic_name": "غيانا",
            "alpha2_code": "GY",
            "alpha3_code": "GUY",
            "phone_code": "592"
        },
        {
            "english_name": "Haiti",
            "arabic_name": "هايتي",
            "alpha2_code": "HT",
            "alpha3_code": "HTI",
            "phone_code": "509"
        },
        {
            "english_name": "Heard Island and McDonald Islands",
            "arabic_name": "جزيرة هيرد وجزر ماكدونالد",
            "alpha2_code": "HM",
            "alpha3_code": "HMD",
            "phone_code": "672"
        },
        {
            "english_name": "Honduras",
            "arabic_name": "هندوراس",
            "alpha2_code": "HN",
            "alpha3_code": "HND",
            "phone_code": "504"
        },
        {
            "english_name": "Hong Kong",
            "arabic_name": "هونج كونج",
            "alpha2_code": "HK",
            "alpha3_code": "HKG",
            "phone_code": "852"
        },
        {
            "english_name": "Hungary",
            "arabic_name": "هنجاريا",
            "alpha2_code": "HU",
            "alpha3_code": "HUN",
            "phone_code": "36"
        },
        {
            "english_name": "Iceland",
            "arabic_name": "آيسلندا",
            "alpha2_code": "IS",
            "alpha3_code": "ISL",
            "phone_code": "354"
        },
        {
            "english_name": "India",
            "arabic_name": "الهند",
            "alpha2_code": "IN",
            "alpha3_code": "IND",
            "phone_code": "91"
        },
        {
            "english_name": "Indonesia",
            "arabic_name": "أندونيسيا",
            "alpha2_code": "ID",
            "alpha3_code": "IDN",
            "phone_code": "62"
        },
        {
            "english_name": "Iran",
            "arabic_name": "إيران",
            "alpha2_code": "IR",
            "alpha3_code": "IRN",
            "phone_code": "98"
        },
        {
            "english_name": "Iraq",
            "arabic_name": "العراق",
            "alpha2_code": "IQ",
            "alpha3_code": "IRQ",
            "phone_code": "964"
        },
        {
            "english_name": "Ireland",
            "arabic_name": "أيرلندا",
            "alpha2_code": "IE",
            "alpha3_code": "IRL",
            "phone_code": "353"
        },
        {
            "english_name": "Isle of Man",
            "arabic_name": "جزيرة مان",
            "alpha2_code": "IM",
            "alpha3_code": "IMN",
            "phone_code": "44"
        },
        {
            "english_name": "Italy",
            "arabic_name": "إيطاليا",
            "alpha2_code": "IT",
            "alpha3_code": "ITA",
            "phone_code": "390"
        },
        {
            "english_name": "Ivory Coast",
            "arabic_name": "ساحل العاج",
            "alpha2_code": "CI",
            "alpha3_code": "CIV",
            "phone_code": "225"
        },
        {
            "english_name": "Jamaica",
            "arabic_name": "جامايكا",
            "alpha2_code": "JM",
            "alpha3_code": "JAM",
            "phone_code": "1876"
        },
        {
            "english_name": "Japan",
            "arabic_name": "اليابان",
            "alpha2_code": "JP",
            "alpha3_code": "JPN",
            "phone_code": "81"
        },
        {
            "english_name": "Jersey",
            "arabic_name": "جيرسي",
            "alpha2_code": "JE",
            "alpha3_code": "JEY",
            "phone_code": "44"
        },
        {
            "english_name": "Jordan",
            "arabic_name": "الأردن",
            "alpha2_code": "JO",
            "alpha3_code": "JOR",
            "phone_code": "962"
        },
        {
            "english_name": "Kazakhstan",
            "arabic_name": "كازاخستان",
            "alpha2_code": "KZ",
            "alpha3_code": "KAZ",
            "phone_code": "7"
        },
        {
            "english_name": "Kenya",
            "arabic_name": "كينيا",
            "alpha2_code": "KE",
            "alpha3_code": "KEN",
            "phone_code": "254"
        },
        {
            "english_name": "Kiribati",
            "arabic_name": "كيريباتي",
            "alpha2_code": "KI",
            "alpha3_code": "KIR",
            "phone_code": "686"
        },
        {
            "english_name": "Kosovo",
            "arabic_name": "كوسوفو",
            "alpha2_code": "XK",
            "alpha3_code": "XKX",
            "phone_code": "383"
        },
        {
            "english_name": "Kuwait",
            "arabic_name": "الكويت",
            "alpha2_code": "KW",
            "alpha3_code": "KWT",
            "phone_code": "965"
        },
        {
            "english_name": "Kyrgyzstan",
            "arabic_name": "قيرغيزستان",
            "alpha2_code": "KG",
            "alpha3_code": "KGZ",
            "phone_code": "996"
        },
        {
            "english_name": "Laos",
            "arabic_name": "لاوس",
            "alpha2_code": "LA",
            "alpha3_code": "LAO",
            "phone_code": "856"
        },
        {
            "english_name": "Latvia",
            "arabic_name": "لاتفيا",
            "alpha2_code": "LV",
            "alpha3_code": "LVA",
            "phone_code": "371"
        },
        {
            "english_name": "Lebanon",
            "arabic_name": "لبنان",
            "alpha2_code": "LB",
            "alpha3_code": "LBN",
            "phone_code": "961"
        },
        {
            "english_name": "Lesotho",
            "arabic_name": "ليسوتو",
            "alpha2_code": "LS",
            "alpha3_code": "LSO",
            "phone_code": "266"
        },
        {
            "english_name": "Liberia",
            "arabic_name": "ليبيريا",
            "alpha2_code": "LR",
            "alpha3_code": "LBR",
            "phone_code": "231"
        },
        {
            "english_name": "Libya",
            "arabic_name": "ليبيا",
            "alpha2_code": "LY",
            "alpha3_code": "LBY",
            "phone_code": "218"
        },
        {
            "english_name": "Liechtenstein",
            "arabic_name": "ليختنشتاين",
            "alpha2_code": "LI",
            "alpha3_code": "LIE",
            "phone_code": "423"
        },
        {
            "english_name": "Lithuania",
            "arabic_name": "ليتوانيا",
            "alpha2_code": "LT",
            "alpha3_code": "LTU",
            "phone_code": "370"
        },
        {
            "english_name": "Luxembourg",
            "arabic_name": "لوكسمبورغ",
            "alpha2_code": "LU",
            "alpha3_code": "LUX",
            "phone_code": "352"
        },
        {
            "english_name": "Macao",
            "arabic_name": "ماكاو",
            "alpha2_code": "MO",
            "alpha3_code": "MAC",
            "phone_code": "853"
        },
        {
            "english_name": "Madagascar",
            "arabic_name": "مدغشقر",
            "alpha2_code": "MG",
            "alpha3_code": "MDG",
            "phone_code": "261"
        },
        {
            "english_name": "Malawi",
            "arabic_name": "مالاوي",
            "alpha2_code": "MW",
            "alpha3_code": "MWI",
            "phone_code": "265"
        },
        {
            "english_name": "Malaysia",
            "arabic_name": "ماليزيا",
            "alpha2_code": "MY",
            "alpha3_code": "MYS",
            "phone_code": "60"
        },
        {
            "english_name": "Maldives",
            "arabic_name": "المالديف",
            "alpha2_code": "MV",
            "alpha3_code": "MDV",
            "phone_code": "960"
        },
        {
            "english_name": "Mali",
            "arabic_name": "مالي",
            "alpha2_code": "ML",
            "alpha3_code": "MLI",
            "phone_code": "223"
        },
        {
            "english_name": "Malta",
            "arabic_name": "مالطا",
            "alpha2_code": "MT",
            "alpha3_code": "MLT",
            "phone_code": "356"
        },
        {
            "english_name": "Marshall Islands",
            "arabic_name": "جزر مارشال",
            "alpha2_code": "MH",
            "alpha3_code": "MHL",
            "phone_code": "692"
        },
        {
            "english_name": "Martinique",
            "arabic_name": "مارتينيك",
            "alpha2_code": "MQ",
            "alpha3_code": "MTQ",
            "phone_code": "33"
        },
        {
            "english_name": "Mauritania",
            "arabic_name": "موريتانيا",
            "alpha2_code": "MR",
            "alpha3_code": "MRT",
            "phone_code": "222"
        },
        {
            "english_name": "Mauritius",
            "arabic_name": "موريشيوس",
            "alpha2_code": "MU",
            "alpha3_code": "MUS",
            "phone_code": "230"
        },
        {
            "english_name": "Mayotte",
            "arabic_name": "مايوت",
            "alpha2_code": "YT",
            "alpha3_code": "MYT",
            "phone_code": "262"
        },
        {
            "english_name": "Mexico",
            "arabic_name": "المكسيك",
            "alpha2_code": "MX",
            "alpha3_code": "MEX",
            "phone_code": "52"
        },
        {
            "english_name": "Micronesia",
            "arabic_name": "ولايات ميكرونيسيا المتحدة",
            "alpha2_code": "FM",
            "alpha3_code": "FSM",
            "phone_code": "691"
        },
        {
            "english_name": "Moldova",
            "arabic_name": "مولدوفا",
            "alpha2_code": "MD",
            "alpha3_code": "MDA",
            "phone_code": "373"
        },
        {
            "english_name": "Monaco",
            "arabic_name": "موناكو",
            "alpha2_code": "MC",
            "alpha3_code": "MCO",
            "phone_code": "377"
        },
        {
            "english_name": "Mongolia",
            "arabic_name": "منغوليا",
            "alpha2_code": "MN",
            "alpha3_code": "MNG",
            "phone_code": "976"
        },
        {
            "english_name": "Montenegro",
            "arabic_name": "مونتينيغرو",
            "alpha2_code": "ME",
            "alpha3_code": "MNE",
            "phone_code": "382"
        },
        {
            "english_name": "Montserrat",
            "arabic_name": "مونتسرات",
            "alpha2_code": "MS",
            "alpha3_code": "MSR",
            "phone_code": "1664"
        },
        {
            "english_name": "Morocco",
            "arabic_name": "المغرب",
            "alpha2_code": "MA",
            "alpha3_code": "MAR",
            "phone_code": "212"
        },
        {
            "english_name": "Mozambique",
            "arabic_name": "موزمبيق",
            "alpha2_code": "MZ",
            "alpha3_code": "MOZ",
            "phone_code": "258"
        },
        {
            "english_name": "Myanmar",
            "arabic_name": "ميانمار",
            "alpha2_code": "MM",
            "alpha3_code": "MMR",
            "phone_code": "95"
        },
        {
            "english_name": "Namibia",
            "arabic_name": "ناميبيا",
            "alpha2_code": "NA",
            "alpha3_code": "NAM",
            "phone_code": "264"
        },
        {
            "english_name": "Nauru",
            "arabic_name": "ناورو",
            "alpha2_code": "NR",
            "alpha3_code": "NRU",
            "phone_code": "674"
        },
        {
            "english_name": "Nepal",
            "arabic_name": "نيبال",
            "alpha2_code": "NP",
            "alpha3_code": "NPL",
            "phone_code": "977"
        },
        {
            "english_name": "Netherlands",
            "arabic_name": "هولندا",
            "alpha2_code": "NL",
            "alpha3_code": "NLD",
            "phone_code": "31"
        },
        {
            "english_name": "Netherlands Antilles",
            "arabic_name": "جزر الأنتيل الهولندية",
            "alpha2_code": "AN",
            "alpha3_code": "ANT",
            "phone_code": "599"
        },
        {
            "english_name": "New Caledonia",
            "arabic_name": "كاليدونيا الجديدة",
            "alpha2_code": "NC",
            "alpha3_code": "NCL",
            "phone_code": "687"
        },
        {
            "english_name": "New Zealand",
            "arabic_name": "نيوزيلندا",
            "alpha2_code": "NZ",
            "alpha3_code": "NZL",
            "phone_code": "64"
        },
        {
            "english_name": "Nicaragua",
            "arabic_name": "نيكاراغوا",
            "alpha2_code": "NI",
            "alpha3_code": "NIC",
            "phone_code": "505"
        },
        {
            "english_name": "Niger",
            "arabic_name": "النيجر",
            "alpha2_code": "NE",
            "alpha3_code": "NER",
            "phone_code": "227"
        },
        {
            "english_name": "Nigeria",
            "arabic_name": "نيجيريا",
            "alpha2_code": "NG",
            "alpha3_code": "NGA",
            "phone_code": "234"
        },
        {
            "english_name": "Niue",
            "arabic_name": "نييوي",
            "alpha2_code": "NU",
            "alpha3_code": "NIU",
            "phone_code": "683"
        },
        {
            "english_name": "Norfolk Island",
            "arabic_name": "جزيرة نورفولك",
            "alpha2_code": "NF",
            "alpha3_code": "NFK",
            "phone_code": "6723"
        },
        {
            "english_name": "North Korea",
            "arabic_name": "كوريا الشمالية",
            "alpha2_code": "KP",
            "alpha3_code": "PRK",
            "phone_code": "850"
        },
        {
            "english_name": "North Macedonia",
            "arabic_name": "مقدونيا الشمالية",
            "alpha2_code": "MK",
            "alpha3_code": "MKD",
            "phone_code": "389"
        },
        {
            "english_name": "Northern Mariana Islands",
            "arabic_name": "جزر ماريانا الشمالية",
            "alpha2_code": "MP",
            "alpha3_code": "MNP",
            "phone_code": "1"
        },
        {
            "english_name": "Norway",
            "arabic_name": "النرويج",
            "alpha2_code": "NO",
            "alpha3_code": "NOR",
            "phone_code": "47"
        },
        {
            "english_name": "Oman",
            "arabic_name": "سلطنة عمان",
            "alpha2_code": "OM",
            "alpha3_code": "OMN",
            "phone_code": "968"
        },
        {
            "english_name": "Pakistan",
            "arabic_name": "باكستان",
            "alpha2_code": "PK",
            "alpha3_code": "PAK",
            "phone_code": "92"
        },
        {
            "english_name": "Palau",
            "arabic_name": "بالاو",
            "alpha2_code": "PW",
            "alpha3_code": "PLW",
            "phone_code": "680"
        },
        {
            "english_name": "Palestine",
            "arabic_name": "فلسطين",
            "alpha2_code": "PS",
            "alpha3_code": "PSE",
            "phone_code": "970"
        },
        {
            "english_name": "Panama",
            "arabic_name": "بنما",
            "alpha2_code": "PA",
            "alpha3_code": "PAN",
            "phone_code": "507"
        },
        {
            "english_name": "Papua New Guinea",
            "arabic_name": "بابوا غينيا الجديدة",
            "alpha2_code": "PG",
            "alpha3_code": "PNG",
            "phone_code": "675"
        },
        {
            "english_name": "Paraguay",
            "arabic_name": "باراغواي",
            "alpha2_code": "PY",
            "alpha3_code": "PRY",
            "phone_code": "595"
        },
        {
            "english_name": "Peru",
            "arabic_name": "بيرو",
            "alpha2_code": "PE",
            "alpha3_code": "PER",
            "phone_code": "51"
        },
        {
            "english_name": "Philippines",
            "arabic_name": "الفلبين",
            "alpha2_code": "PH",
            "alpha3_code": "PHL",
            "phone_code": "63"
        },
        {
            "english_name": "Pitcairn Islands",
            "arabic_name": "جزر بيتكيرن",
            "alpha2_code": "PN",
            "alpha3_code": "PCN",
            "phone_code": "672"
        },
        {
            "english_name": "Poland",
            "arabic_name": "بولندا",
            "alpha2_code": "PL",
            "alpha3_code": "POL",
            "phone_code": "48"
        },
        {
            "english_name": "Portugal",
            "arabic_name": "البرتغال",
            "alpha2_code": "PT",
            "alpha3_code": "PRT",
            "phone_code": "351"
        },
        {
            "english_name": "Puerto Rico",
            "arabic_name": "بورتوريكو",
            "alpha2_code": "PR",
            "alpha3_code": "PRI",
            "phone_code": "1787"
        },
        {
            "english_name": "Qatar",
            "arabic_name": "قطر",
            "alpha2_code": "QA",
            "alpha3_code": "QAT",
            "phone_code": "974"
        },
        {
            "english_name": "Réunion",
            "arabic_name": "ريونيون",
            "alpha2_code": "RE",
            "alpha3_code": "REU",
            "phone_code": "262"
        },
        {
            "english_name": "Romania",
            "arabic_name": "رومانيا",
            "alpha2_code": "RO",
            "alpha3_code": "ROU",
            "phone_code": "40"
        },
        {
            "english_name": "Russia",
            "arabic_name": "روسيا",
            "alpha2_code": "RU",
            "alpha3_code": "RUS",
            "phone_code": "7"
        },
        {
            "english_name": "Rwanda",
            "arabic_name": "رواندا",
            "alpha2_code": "RW",
            "alpha3_code": "RWA",
            "phone_code": "250"
        },
        {
            "english_name": "Saint Barthélemy",
            "arabic_name": "سان بارتيلمي",
            "alpha2_code": "BL",
            "alpha3_code": "BLM",
            "phone_code": "590"
        },
        {
            "english_name": "Saint Helena",
            "arabic_name": "سانت هيلينا",
            "alpha2_code": "SH",
            "alpha3_code": "SHN",
            "phone_code": "290"
        },
        {
            "english_name": "Saint Kitts and Nevis",
            "arabic_name": "سانت كيتس ونيفيس",
            "alpha2_code": "KN",
            "alpha3_code": "KNA",
            "phone_code": "1869"
        },
        {
            "english_name": "Saint Lucia",
            "arabic_name": "سانت لوسيا",
            "alpha2_code": "LC",
            "alpha3_code": "LCA",
            "phone_code": "1758"
        },
        {
            "english_name": "Saint Martin",
            "arabic_name": "سانت مارتن",
            "alpha2_code": "MF",
            "alpha3_code": "MAF",
            "phone_code": "590"
        },
        {
            "english_name": "Saint Pierre and Miquelon",
            "arabic_name": "سان بيير وميكلون",
            "alpha2_code": "PM",
            "alpha3_code": "SPM",
            "phone_code": "508"
        },
        {
            "english_name": "Saint Vincent and the Grenadines",
            "arabic_name": "سانت فينسنت والغرينادين",
            "alpha2_code": "VC",
            "alpha3_code": "VCT",
            "phone_code": "784"
        },
        {
            "english_name": "Samoa",
            "arabic_name": "ساموا",
            "alpha2_code": "WS",
            "alpha3_code": "WSM",
            "phone_code": "685"
        },
        {
            "english_name": "San Marino",
            "arabic_name": "سان مارينو",
            "alpha2_code": "SM",
            "alpha3_code": "SMR",
            "phone_code": "378"
        },
        {
            "english_name": "São Tomé and Príncipe",
            "arabic_name": "ساو تومي وبرينسيب",
            "alpha2_code": "ST",
            "alpha3_code": "STP",
            "phone_code": "239"
        },
        {
            "english_name": "Saudi Arabia",
            "arabic_name": "السعودية",
            "alpha2_code": "SA",
            "alpha3_code": "SAU",
            "phone_code": "966"
        },
        {
            "english_name": "Senegal",
            "arabic_name": "السنغال",
            "alpha2_code": "SN",
            "alpha3_code": "SEN",
            "phone_code": "221"
        },
        {
            "english_name": "Serbia",
            "arabic_name": "صربيا",
            "alpha2_code": "RS",
            "alpha3_code": "SRB",
            "phone_code": "381"
        },
        {
            "english_name": "Seychelles",
            "arabic_name": "سيشل",
            "alpha2_code": "SC",
            "alpha3_code": "SYC",
            "phone_code": "248"
        },
        {
            "english_name": "Sierra Leone",
            "arabic_name": "سيراليون",
            "alpha2_code": "SL",
            "alpha3_code": "SLE",
            "phone_code": "232"
        },
        {
            "english_name": "Singapore",
            "arabic_name": "سنغافورة",
            "alpha2_code": "SG",
            "alpha3_code": "SGP",
            "phone_code": "65"
        },
        {
            "english_name": "Sint Maarten",
            "arabic_name": "سانت مارتن",
            "alpha2_code": "SX",
            "alpha3_code": "SXM",
            "phone_code": "599"
        },
        {
            "english_name": "Slovakia",
            "arabic_name": "سلوفاكيا",
            "alpha2_code": "SK",
            "alpha3_code": "SVK",
            "phone_code": "421"
        },
        {
            "english_name": "Slovenia",
            "arabic_name": "سلوفينيا",
            "alpha2_code": "SI",
            "alpha3_code": "SVN",
            "phone_code": "386"
        },
        {
            "english_name": "Solomon Islands",
            "arabic_name": "جزر سليمان",
            "alpha2_code": "SB",
            "alpha3_code": "SLB",
            "phone_code": "677"
        },
        {
            "english_name": "Somalia",
            "arabic_name": "الصومال",
            "alpha2_code": "SO",
            "alpha3_code": "SOM",
            "phone_code": "252"
        },
        {
            "english_name": "South Africa",
            "arabic_name": "جنوب أفريقيا",
            "alpha2_code": "ZA",
            "alpha3_code": "ZAF",
            "phone_code": "27"
        },
        {
            "english_name": "South Georgia and South Sandwich Islands",
            "arabic_name": "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
            "alpha2_code": "GS",
            "alpha3_code": "SGS",
            "phone_code": "500"
        },
        {
            "english_name": "South Korea",
            "arabic_name": "كوريا الجنوبية",
            "alpha2_code": "KR",
            "alpha3_code": "KOR",
            "phone_code": "82"
        },
        {
            "english_name": "South Sudan",
            "arabic_name": "جنوب السودان",
            "alpha2_code": "SS",
            "alpha3_code": "SSD",
            "phone_code": "211"
        },
        {
            "english_name": "Spain",
            "arabic_name": "إسبانيا",
            "alpha2_code": "ES",
            "alpha3_code": "ESP",
            "phone_code": "34"
        },
        {
            "english_name": "Sri Lanka",
            "arabic_name": "سريلانكا",
            "alpha2_code": "LK",
            "alpha3_code": "LKA",
            "phone_code": "94"
        },
        {
            "english_name": "Sudan",
            "arabic_name": "السودان",
            "alpha2_code": "SD",
            "alpha3_code": "SDN",
            "phone_code": "249"
        },
        {
            "english_name": "Suriname",
            "arabic_name": "سورينام",
            "alpha2_code": "SR",
            "alpha3_code": "SUR",
            "phone_code": "597"
        },
        {
            "english_name": "Svalbard and Jan Mayen",
            "arabic_name": "سفالبارد ويان ماين",
            "alpha2_code": "SJ",
            "alpha3_code": "SJM",
            "phone_code": "47"
        },
        {
            "english_name": "Sweden",
            "arabic_name": "السويد",
            "alpha2_code": "SE",
            "alpha3_code": "SWE",
            "phone_code": "46"
        },
        {
            "english_name": "Switzerland",
            "arabic_name": "سويسرا",
            "alpha2_code": "CH",
            "alpha3_code": "CHE",
            "phone_code": "41"
        },
        {
            "english_name": "Syria",
            "arabic_name": "سوريا",
            "alpha2_code": "SY",
            "alpha3_code": "SYR",
            "phone_code": "963"
        },
        {
            "english_name": "Taiwan",
            "arabic_name": "تايوان",
            "alpha2_code": "TW",
            "alpha3_code": "TWN",
            "phone_code": "886"
        },
        {
            "english_name": "Tajikistan",
            "arabic_name": "طاجيكستان",
            "alpha2_code": "TJ",
            "alpha3_code": "TJK",
            "phone_code": "992"
        },
        {
            "english_name": "Tanzania",
            "arabic_name": "تنزانيا",
            "alpha2_code": "TZ",
            "alpha3_code": "TZA",
            "phone_code": "255"
        },
        {
            "english_name": "Thailand",
            "arabic_name": "تايلاند",
            "alpha2_code": "TH",
            "alpha3_code": "THA",
            "phone_code": "66"
        },
        {
            "english_name": "Timor-Leste",
            "arabic_name": "تيمور الشرقية",
            "alpha2_code": "TL",
            "alpha3_code": "TLS",
            "phone_code": "670"
        },
        {
            "english_name": "Togo",
            "arabic_name": "توجو",
            "alpha2_code": "TG",
            "alpha3_code": "TGO",
            "phone_code": "228"
        },
        {
            "english_name": "Tokelau",
            "arabic_name": "توكيلاو",
            "alpha2_code": "TK",
            "alpha3_code": "TKL",
            "phone_code": "690"
        },
        {
            "english_name": "Tonga",
            "arabic_name": "تونغا",
            "alpha2_code": "TO",
            "alpha3_code": "TON",
            "phone_code": "676"
        },
        {
            "english_name": "Trinidad and Tobago",
            "arabic_name": "ترينيداد وتوباغو",
            "alpha2_code": "TT",
            "alpha3_code": "TTO",
            "phone_code": "868"
        },
        {
            "english_name": "Tunisia",
            "arabic_name": "تونس",
            "alpha2_code": "TN",
            "alpha3_code": "TUN",
            "phone_code": "216"
        },
        {
            "english_name": "Turkey",
            "arabic_name": "تركيا",
            "alpha2_code": "TR",
            "alpha3_code": "TUR",
            "phone_code": "90"
        },
        {
            "english_name": "Turkmenistan",
            "arabic_name": "تركمانستان",
            "alpha2_code": "TM",
            "alpha3_code": "TKM",
            "phone_code": "993"
        },
        {
            "english_name": "Turks and Caicos Islands",
            "arabic_name": "جزر توركس وكايكوس",
            "alpha2_code": "TC",
            "alpha3_code": "TCA",
            "phone_code": "1"
        },
        {
            "english_name": "Tuvalu",
            "arabic_name": "توفالو",
            "alpha2_code": "TV",
            "alpha3_code": "TUV",
            "phone_code": "688"
        },
        {
            "english_name": "U.S. Minor Outlying Islands",
            "arabic_name": "جزر الولايات المتحدة الصغيرة النائية",
            "alpha2_code": "UM",
            "alpha3_code": "UMI",
            "phone_code": "246"
        },
        {
            "english_name": "U.S. Virgin Islands",
            "arabic_name": "جزر العذراء الأمريكية",
            "alpha2_code": "VI",
            "alpha3_code": "VIR",
            "phone_code": "1"
        },
        {
            "english_name": "Uganda",
            "arabic_name": "أوغندا",
            "alpha2_code": "UG",
            "alpha3_code": "UGA",
            "phone_code": "256"
        },
        {
            "english_name": "Ukraine",
            "arabic_name": "أوكرانيا",
            "alpha2_code": "UA",
            "alpha3_code": "UKR",
            "phone_code": "380"
        },
        {
            "english_name": "United Arab Emirates",
            "arabic_name": "الإمارات العربية المتحدة",
            "alpha2_code": "AE",
            "alpha3_code": "ARE",
            "phone_code": "971"
        },
        {
            "english_name": "United Kingdom",
            "arabic_name": "المملكة المتحدة",
            "alpha2_code": "GB",
            "alpha3_code": "GBR",
            "phone_code": "44"
        },
        {
            "english_name": "United States of America",
            "arabic_name": "الولايات المتحدة الأمريكية",
            "alpha2_code": "US",
            "alpha3_code": "USA",
            "phone_code": "1"
        },
        {
            "english_name": "Uruguay",
            "arabic_name": "أوروغواي",
            "alpha2_code": "UY",
            "alpha3_code": "URY",
            "phone_code": "598"
        },
        {
            "english_name": "Uzbekistan",
            "arabic_name": "أوزبكستان",
            "alpha2_code": "UZ",
            "alpha3_code": "UZB",
            "phone_code": "998"
        },
        {
            "english_name": "Vanuatu",
            "arabic_name": "فانواتو",
            "alpha2_code": "VU",
            "alpha3_code": "VUT",
            "phone_code": "678"
        },
        {
            "english_name": "Vatican City",
            "arabic_name": "مدينة الفاتيكان",
            "alpha2_code": "VA",
            "alpha3_code": "VAT",
            "phone_code": "379"
        },
        {
            "english_name": "Venezuela",
            "arabic_name": "فنزويلا",
            "alpha2_code": "VE",
            "alpha3_code": "VEN",
            "phone_code": "58"
        },
        {
            "english_name": "Vietnam",
            "arabic_name": "فيتنام",
            "alpha2_code": "VN",
            "alpha3_code": "VNM",
            "phone_code": "84"
        },
        {
            "english_name": "Wallis and Futuna",
            "arabic_name": "واليس وفوتونا",
            "alpha2_code": "WF",
            "alpha3_code": "WLF",
            "phone_code": "681"
        },
        {
            "english_name": "Western Sahara",
            "arabic_name": "الصحراء الغربية",
            "alpha2_code": "EH",
            "alpha3_code": "ESH",
            "phone_code": "212"
        },
        {
            "english_name": "Yemen",
            "arabic_name": "اليمن",
            "alpha2_code": "YE",
            "alpha3_code": "YEM",
            "phone_code": "967"
        },
        {
            "english_name": "Zambia",
            "arabic_name": "زامبيا",
            "alpha2_code": "ZM",
            "alpha3_code": "ZMB",
            "phone_code": "260"
        },
        {
            "english_name": "Zimbabwe",
            "arabic_name": "زيمبابوي",
            "alpha2_code": "ZW",
            "alpha3_code": "ZWE",
            "phone_code": "263"
        }
    ]

    const location = useLocation();
    let setTriggerScroll = location.state?.setTriggerScroll || false;

    const [isHovered, setIsHovered] = useState(false);
    const textFieldStyle = {
        color: 'white',
        borderBottom: '1px solid white', // Initial underline style
    };
    const redAsteriskStyle = {
        color: 'red',
    };
    const [showModal, setShowModal] = useState(false);
    const [showModalmob, setShowModalmob] = useState(false);
    const handleTextFieldClick = () => {
        setShowModal(true);
    };
    const handleTextFieldClickmob = () => {
        setShowModalmob(true);
    };
    const [emailSent, setEmailSent] = useState(false);
    const [showAlerts, setShowAlerts] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [checked, setChecked] = useState(false);

    const handleRecaptchaChange = (value) => {
        if (value) {
            setIsVerified(true);
        } else {
            setIsVerified(false);
        }

    };

    const [showIntegratedServices, setShowIntegratedServices] = useState(false);

    useEffect(() => {
        if (setTriggerScroll) {
            // Scroll to the contactForm div
            const contactForm = document.getElementById('contactForm');
            if (contactForm) {
                contactForm.scrollIntoView({
                    behavior: 'smooth',
                });
            }
            // Reset triggerScroll after scrolling
            setTriggerScroll = false;
        }
    }, [setTriggerScroll]);
    const [selectedDateTime, setSelectedDateTime] = useState(null);
    const handleSelectedDateTime = (dateTime) => {
        setSelectedDateTime(dateTime);
        setInputValue(dateTime);

    };

    const [formData, setFormData] = useState({
        serviceType: '',
        yourSector: '',
        fullName: '',
        email: '',
        phoneNumber: '',
        CallTime: '',
        jobTitle: '',
        companySize: '',
        Country: '',
        Checked: checked
    });
    const handleInputChange = (field) => (event) => {
        const value = event.target.value;
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));

        console.log(formData);
    };
    const handleIntegratedServiceChange = (event) => {
        const integratedService = event.target.value;
        setFormData((prevData) => ({
            ...prevData,
            serviceType: `Integrated Services - ${integratedService}`,
        }));
    };
    useEffect(() => {
        if (formData.serviceType.startsWith('Integrated Services')) {
            setShowIntegratedServices(true);
        } else {
            setShowIntegratedServices(false);
        }
    }, [formData.serviceType]);

    useEffect(() => {
        // console.log(formData);
    }, [formData]);
    const handleSendButtonClick = async () => {


        if (isVerified) {
            // if (true) {
            setIsLoading(true);
            const updatedFormData = {
                ...formData,
                CallTime: selectedDateTime,
                Checked: checked
            };
            console.log(updatedFormData);

            // Regex pattern to match time in HH:MM or HH:MM:SS format
            const timePattern = /^([01]\d|2[0-3]):([0-5]\d)(:([0-5]\d))?$/;

            if (
                updatedFormData.serviceType.trim() === '' || // Check if empty or only spaces
                updatedFormData.yourSector.trim() === '' ||
                updatedFormData.fullName.trim() === '' ||
                updatedFormData.email.trim() === '' ||
                updatedFormData.phoneNumber.trim() === '' ||
                updatedFormData.CallTime === '' || // Check if empty or only spaces
                // !timePattern.test(updatedFormData.CallTime.trim()) || // Validate time format
                updatedFormData.jobTitle.trim() === '' ||
                updatedFormData.companySize.trim() === '' ||
                updatedFormData.Country.trim() === '' ||
                !checked
            ) {
                console.error('Please fill in all the fields correctly before sending the email');
                setEmailSent(false);
                setIsLoading(false);
                setErrorMessage("Please fill all fields correctly and ensure the time is valid.");
                setShowAlerts(true); // Show alerts even if there's an error
                return; // Exit the function if any field is empty or contains invalid data
            }



            // Update formData with the selectedDateTime

            try {
                setIsVerified(false);

                const response = await fetch('https://data.banknoteconsult.com/FinancialForm/mails.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: new URLSearchParams(updatedFormData)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                setEmailSent(true);
                setShowAlerts(true); // Show alerts after email is sent
            } catch (error) {
                console.error('There was an error submitting the form:', error.message);
                setEmailSent(false);
                setErrorMessage("Please check your connectivity.");
                setShowAlerts(true); // Show alerts even if there's an error
            }
            setIsLoading(false);
        }
    };

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        setChecked(isChecked);
        // Update formData with 'yes' or 'no' based on isChecked
        setFormData((prevData) => ({
            ...prevData,
            Checked: isChecked ? 'true' : 'false'
        }));
    };


    const [formDataMob, setFormDataMob] = useState({
        serviceType: '',
        yourSector: '',
        fullName: '',
        email: '',
        phoneNumber: '',
        CallTime: '',
        jobTitle: '',
        companySize: '',
        Country: '',
        Checked: ''


    });
    const [selectedMobDateTime, setSelectedMobDateTime] = useState(null);
    const handleMobSelectedDateTime = (dateTime) => {
        setSelectedMobDateTime(dateTime);
        setInputValue(dateTime);

    };
    const [showIntegratedServicesMob, setShowIntegratedServicesMob] = useState(false);
    const handleMobInputChange = (field) => (event) => {
        setFormDataMob((prevData) => ({
            ...prevData,
            [field]: event.target.value,
        }));
        console.log(formDataMob);
    };

    const handleIntegratedServiceMobChange = (event) => {
        const integratedService = event.target.value;
        setFormDataMob((prevData) => ({
            ...prevData,
            serviceType: `Integrated Services - ${integratedService}`,
        }));
    };
    useEffect(() => {
        if (formDataMob.serviceType.startsWith('Integrated Services')) {
            setShowIntegratedServicesMob(true);
        } else {
            setShowIntegratedServicesMob(false);
        }
    }, [formDataMob.serviceType]);

    useEffect(() => {
        // console.log(formDataMob);
    }, [formDataMob]);

    const handleMobSendButtonClick = async () => {
        if (isVerified) {
            //  if (true) {

            const updatedFormDataMob = {
                ...formDataMob,
                CallTime: selectedMobDateTime,
                Checked: checked

            };
            const timePattern = /^([01]\d|2[0-3]):([0-5]\d)(:([0-5]\d))?$/;

            if (
                updatedFormDataMob.serviceType.trim() === '' || // Check if empty or only spaces
                updatedFormDataMob.yourSector.trim() === '' ||
                updatedFormDataMob.fullName.trim() === '' ||
                updatedFormDataMob.email.trim() === '' ||
                updatedFormDataMob.phoneNumber.trim() === '' ||
                updatedFormDataMob.CallTime === '' || // Check if empty or only spaces
                // !timePattern.test(updatedFormDataMob.CallTime.trim()) || // Validate time format
                updatedFormDataMob.jobTitle.trim() === '' ||
                updatedFormDataMob.companySize.trim() === '' ||
                updatedFormDataMob.Country.trim() === '' ||
                !checked

            ) {
                console.error('Please fill in all the fields correctly before sending the email');
                setEmailSent(false);
                setErrorMessage("Please fill all fields correctly and ensure the time is valid.");
                setShowAlerts(true); // Show alerts even if there's an error
                return; // Exit the function if any field is empty or contains invalid data
            }



            try {
                setIsVerified(false);

                const response = await fetch('https://data.banknoteconsult.com/FinancialForm/mails.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: new URLSearchParams(updatedFormDataMob)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                setEmailSent(true);
                setShowAlerts(true); // Show alerts after email is sent

            } catch (error) {
                console.error('There was an error submitting the form:', error.message);
                setEmailSent(false);
                setErrorMessage("Message not sent, Please check your connectivity.");
                setShowAlerts(true); // Show alerts even if there's an error
            }

        }
    };
    return (
        <div>

            <div className={styles.mobPageContainer} style={{ maxHeight: '0', backgroundSize: 'cover', backgroundAttachment: 'fixed' }}>
                <div className={styles.submobPageContainer}>

                    <div className={styles.mobheadline}>
                        <h1>FAQ<span style={{ color: '#E4C78B' }}>s</span></h1>
                        <h2>Financial Solutions</h2>
                    </div>
                    <div className={styles.SubframesCont1}>
                        <MobFAQFrame
                            question="What are the key benefits of using your financial consulting services?"

                            additionalText="The key benefits of utilizing our financial consulting services include personalized solutions aligned with your financial goals, expert guidance from seasoned professionals, and the assurance of financial peace of mind through strategic planning and optimization of resources."
                        />
                        <MobFAQFrame
                            question="How do you ensure compliance with financial regulations and industry standards?"

                            additionalText="To guarantee compliance with financial regulations and industry standards, we employ meticulous monitoring practices, staying abreast of evolving regulations, and implementing proactive measures to ensure our clients operate within the bounds of legal and industry requirements."
                        />
                        <MobFAQFrame
                            question="How do you address financial challenges unique to our industry?"

                            additionalText="In addressing financial challenges unique to your industry, we leverage our in-depth understanding to tailor solutions. "
                        />
                    </div>
                    <div className={styles.mobheadline} style={{ marginTop: '10%', marginBottom: '2%' }}>

                        <h2>Exporting & Trading</h2>
                    </div>
                    <div className={styles.SubframesCont1}>
                        <MobFAQFrame
                            question="Which regions or countries do you facilitate trades & exports to and from?"

                            additionalText="Our company is committed to facilitating trade and exports globally. We have a widespread network and the capability to export our products to various regions and countries around the world."
                        />
                        <MobFAQFrame
                            question="What shipping methods and logistics solutions do you offer?"

                            additionalText="We provide a range of shipping methods and logistics solutions, Our services aim to offer flexibility, efficiency, and cost-effectiveness to meet diverse shipping needs."
                        />
                        <MobFAQFrame
                            question="How do you handle customs and regulatory requirements for international trade?"

                            additionalText="Our dedicated team is well-versed in global regulations, streamlining the customs clearance process and minimizing potential delays to facilitate seamless international trade transactions."
                        />
                    </div>

                    <div className={styles.cropedCont} id="contactFormMob2" >
                        <div className={styles.mobbglogo}>
                            <img src="/bgmob.png" alt="" />
                        </div>
                        <div className={styles.mobContactLabelCont}>
                            <h1>
                                Get in touch,
                            </h1>
                            <h1>
                                Banknote here to
                            </h1>

                            <h1 id="contactFormMob1" style={{ color: '#E4C78B' }}>
                                Help your Business!
                            </h1>
                        </div>
                        <div id="contactFormMob" className={styles.contactForm} style={{ marginBottom: '10%' }}>
                            <div className={styles.mobunderlinetextfield}>
                                <div className={styles.submobunderlinetextfield} id="contactFormMob3">

                                    {/* <TextField
                                        id="company-name"
                                        label={<span>Company Name <span style={redAsteriskStyle}>*</span></span>}
                                        variant="standard"
                                        inputProps={{ style: textFieldStyle }}
                                        InputLabelProps={{
                                            style: { color: 'white' }, // Color for label
                                        }}
                                        sx={{
                                            '& .MuiInput-underline:after': {
                                                borderBottomColor: '#DDB96E', // Change focus underline color to black
                                            },
                                        }}
                                        value={formDataMob.companyName}
                                        onChange={handleMobInputChange('companyName')}

                                    /> */}



                                    <TextField
                                        id="full-name"
                                        label={<span>Full Name <span style={redAsteriskStyle}>*</span></span>}
                                        variant="standard"
                                        inputProps={{ style: textFieldStyle }}
                                        InputLabelProps={{
                                            style: { color: 'white' }, // Color for label
                                        }}
                                        sx={{
                                            '& .MuiInput-underline:after': {
                                                borderBottomColor: '#DDB96E', // Change focus underline color to black
                                            },
                                        }}
                                        value={formDataMob.fullName}
                                        onChange={handleMobInputChange('fullName')}
                                    />



                                    <TextField
                                        id="phone-number"
                                        label={<span>Phone Number <span style={redAsteriskStyle}>*</span></span>}
                                        variant="standard"
                                        inputProps={{ style: textFieldStyle, type: 'tel' }} // Set type attribute to 'tel' for mobile keypad
                                        InputLabelProps={{
                                            style: { color: 'white' }, // Color for label
                                        }}
                                        sx={{
                                            '& .MuiInput-underline:after': {
                                                borderBottomColor: '#DDB96E', // Change focus underline color to black
                                            },
                                        }}
                                        value={formDataMob.phoneNumber}
                                        onChange={handleMobInputChange('phoneNumber')}
                                    />
                                    <FormControl variant="standard" sx={{
                                        '& .MuiInput-underline:after': {
                                            borderBottomColor: '#DDB96E', // Change focus underline color to white
                                        },
                                        '& .MuiInput-underline:before': {
                                            borderBottomColor: 'rgba(255, 255, 255, 0.637)', // Change default underline color to white
                                        },
                                        '& .MuiInputBase-input': {
                                            color: 'white', // Change font color to white
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'white', // Change label color to white
                                        },
                                        '& .MuiSvgIcon-root': {
                                            color: 'white', // Change the color of the arrow to white
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: 'white', // Change label color to white when focused
                                        },
                                    }}>
                                        <InputLabel id="demo-simple-select-standard-label">
                                            {<span>Job Title <span style={redAsteriskStyle}>*</span></span>}
                                        </InputLabel>
                                        <Select
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        backgroundColor: '#262729', // Change the background color here
                                                        color: 'white',
                                                        fontFamily: 'Cairo Light'
                                                    }
                                                }
                                            }}
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={formDataMob.jobTitle} // The value selected in the dropdown (age variable)
                                            onChange={handleMobInputChange('jobTitle')} // Function to handle changes in the dropdown
                                            label={<span>Service Type <span style={redAsteriskStyle}>*</span></span>} // Label for the select input
                                        >
                                            <MenuItem value="Business Owner">Business Owner</MenuItem>
                                            <MenuItem value="Marketing Manager">Marketing Manager</MenuItem>
                                            <MenuItem value="Financial Manager">Financial Manager</MenuItem>
                                            <MenuItem value="Export Manager">Export Manager</MenuItem>
                                            <MenuItem value="Sales Manager">Sales Manager</MenuItem>




                                        </Select>
                                    </FormControl>

                                    <FormControl variant="standard" sx={{
                                        '& .MuiInput-underline:after': {
                                            borderBottomColor: '#DDB96E', // Change focus underline color to white
                                        },
                                        '& .MuiInput-underline:before': {
                                            borderBottomColor: 'rgba(255, 255, 255, 0.637)', // Change default underline color to white
                                        },
                                        '& .MuiInputBase-input': {
                                            color: 'white', // Change font color to white
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'white', // Change label color to white
                                        },
                                        '& .MuiSvgIcon-root': {
                                            color: 'white', // Change the color of the arrow to white
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: 'white', // Change label color to white when focused
                                        },
                                    }}>
                                        <InputLabel id="demo-simple-select-standard-label">
                                            <span>Country<span style={redAsteriskStyle}>*</span></span>
                                        </InputLabel>
                                        <Select
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        backgroundColor: '#262729', // Change the background color here
                                                        color: 'white',
                                                        fontFamily: 'Cairo Light',
                                                        maxHeight: 200, // Adjust the maximum height of the dropdown list
                                                    }
                                                }
                                            }}
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={formDataMob.Country} // The value selected in the dropdown
                                            onChange={handleMobInputChange('Country')} // Function to handle changes in the dropdown
                                            label={<span>Country<span style={redAsteriskStyle}>*</span></span>} // Label for the select input
                                        >
                                            {countriesList.map(country => (
                                                <MenuItem
                                                    key={country.alpha2_code} // Use a unique key, such as alpha2_code
                                                    className={styles.MenuItem2}
                                                    style={{
                                                        fontFamily: 'Cairo Light', display: 'flex',
                                                        justifyContent: 'start'
                                                    }} // Align text to the left
                                                    value={country.english_name} // Set the value to the English name or any other identifier
                                                >
                                                    {country.english_name} {/* Display the English name or any other representation */}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        id="email"
                                        label={<span>Email <span style={redAsteriskStyle}>*</span></span>}
                                        variant="standard"
                                        inputProps={{ style: textFieldStyle }}
                                        InputLabelProps={{
                                            style: { color: 'white' }, // Color for label
                                        }}
                                        sx={{
                                            '& .MuiInput-underline:after': {
                                                borderBottomColor: '#DDB96E', // Change focus underline color to black
                                            },
                                        }}
                                        value={formDataMob.email}
                                        onChange={handleMobInputChange('email')}
                                    />



                                    <FormControl
                                        variant="standard"
                                        sx={{
                                            '& .MuiInput-underline:after': {
                                                borderBottomColor: '#DDB96E', // Change focus underline color to white
                                            },
                                            '& .MuiInput-underline:before': {
                                                borderBottomColor: 'rgba(255, 255, 255, 0.637)', // Change default underline color to white
                                            },
                                            '& .MuiInputBase-input': {
                                                color: 'white', // Change font color to white
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: 'white', // Change label color to white
                                            },
                                            '& .MuiInputLabel-root.Mui-focused': {
                                                color: 'white', // Change label color to white when focused
                                            },
                                            '& .MuiSvgIcon-root': {
                                                color: 'white', // Change the color of the arrow to white
                                            },
                                        }}
                                    >
                                        <InputLabel id="service-type-label-mob">
                                            <span>Service Type <span style={{ color: 'red' }}>*</span></span>
                                        </InputLabel>
                                        {formDataMob.Country === 'Egypt' ? (
                                            <Select
                                                className={styles.selectedItem2}
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            backgroundColor: '#262729', // Change the background color here
                                                            color: 'white',
                                                            fontFamily: 'Cairo Light',
                                                        },
                                                    },
                                                }}
                                                labelId="service-type-label-mob"
                                                id="service-type-mob"
                                                value={formDataMob.serviceType.split(' - ')[0]} // The value selected in the dropdown
                                                onChange={handleMobInputChange('serviceType')} // Function to handle changes in the dropdown
                                                label={<span>Service Type <span style={{ color: 'red' }}>*</span></span>} // Label for the select input
                                                sx={{
                                                    "& .MuiSvgIcon-root": {
                                                        left: "unset",
                                                        right: "7px",
                                                    },
                                                }}
                                            >
                                                <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Financial Consultations">
                                                    Financial Consulting
                                                </MenuItem>
                                                <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Local Supplies">
                                                    Local Supplies
                                                </MenuItem>
                                                <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Wholesale Trade">
                                                    Wholesale Trade
                                                </MenuItem>
                                                <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Export">
                                                    Export
                                                </MenuItem>
                                                <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Integrated Services">
                                                    Integrated Services
                                                </MenuItem>
                                            </Select>
                                        ) : (
                                            <Select
                                                className={styles.selectedItem2}
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            backgroundColor: '#262729', // Change the background color here
                                                            color: 'white',
                                                            fontFamily: 'Cairo Light',
                                                        },
                                                    },
                                                }}
                                                labelId="service-type-label-mob"
                                                id="service-type-mob"
                                                value={formDataMob.serviceType.split(' - ')[0]} // The value selected in the dropdown
                                                onChange={handleMobInputChange('serviceType')} // Function to handle changes in the dropdown
                                                label={<span>Service Type <span style={{ color: 'red' }}>*</span></span>} // Label for the select input
                                                sx={{
                                                    "& .MuiSvgIcon-root": {
                                                        left: "unset",
                                                        right: "7px",
                                                    },
                                                }}
                                            >
                                                <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Financial Consultations">
                                                    Financial Consulting
                                                </MenuItem>
                                                <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Local Supplies">
                                                    Local Supplies
                                                </MenuItem>
                                                <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Wholesale Trade">
                                                    Wholesale Trade
                                                </MenuItem>
                                                <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Import from Egypt">
                                                    Import from Egypt
                                                </MenuItem>
                                                <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Integrated Services">
                                                    Integrated Services
                                                </MenuItem>
                                            </Select>
                                        )}
                                    </FormControl>

                                    {showIntegratedServicesMob && (
                                        <FormControl
                                            variant="standard"
                                            sx={{
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: '#DDB96E', // Change focus underline color to white
                                                },
                                                '& .MuiInput-underline:before': {
                                                    borderBottomColor: 'rgba(255, 255, 255, 0.637)', // Change default underline color to white
                                                },
                                                '& .MuiInputBase-input': {
                                                    color: 'white', // Change font color to white
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: 'white', // Change label color to white
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    color: 'white', // Change the color of the arrow to white
                                                },
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: 'white', // Change label color to white when focused
                                                },
                                            }}
                                        >
                                            <InputLabel id="integrated-service-label-mob">
                                                <span>Integrated Service<span style={{ color: 'red' }}>*</span></span>
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            backgroundColor: '#262729', // Change the background color here
                                                            color: 'white',
                                                            fontFamily: 'Cairo Light'
                                                        }
                                                    }
                                                }}
                                                labelId="integrated-service-label-mob"
                                                id="integrated-service-mob"
                                                value={formDataMob.serviceType.split(' - ')[1] || ''} // The value selected in the dropdown
                                                onChange={handleIntegratedServiceMobChange} // Function to handle changes in the dropdown
                                                label={<span>Integrated Service <span style={{ color: 'red' }}>*</span></span>} // Label for the select input
                                            >
                                                <MenuItem value="Maintenance">Maintenance</MenuItem>
                                                <MenuItem value="Contracting">Contracting</MenuItem>
                                                <MenuItem value="Transportation">Transportation</MenuItem>
                                                <MenuItem value="Web Design">Web Design</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}

                                    <TextField
                                        id="your-sector"
                                        label={<span>Your Sector <span style={redAsteriskStyle}>*</span></span>}
                                        variant="standard"
                                        inputProps={{ style: textFieldStyle }}
                                        InputLabelProps={{
                                            style: { color: 'white' }, // Color for label
                                        }}
                                        sx={{
                                            '& .MuiInput-underline:after': {
                                                borderBottomColor: '#DDB96E', // Change focus underline color to black
                                            },
                                        }}
                                        value={formDataMob.yourSector}
                                        onChange={handleMobInputChange('yourSector')}
                                    />

                                    <FormControl variant="standard" sx={{
                                        '& .MuiInput-underline:after': {
                                            borderBottomColor: '#DDB96E', // Change focus underline color to white
                                        },
                                        '& .MuiInput-underline:before': {
                                            borderBottomColor: 'rgba(255, 255, 255, 0.637)', // Change default underline color to white
                                        },
                                        '& .MuiInputBase-input': {
                                            color: 'white', // Change font color to white
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'white', // Change label color to white
                                        },
                                        '& .MuiSvgIcon-root': {
                                            color: 'white', // Change the color of the arrow to white
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: 'white', // Change label color to white when focused
                                        },
                                    }}>
                                        <InputLabel id="demo-simple-select-standard-label">
                                            {<span>Company Size<span style={redAsteriskStyle}>*</span></span>}
                                        </InputLabel>
                                        <Select
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        backgroundColor: '#262729', // Change the background color here
                                                        color: 'white',
                                                        fontFamily: 'Cairo Light'
                                                    }
                                                }
                                            }}
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={formDataMob.companySize} // The value selected in the dropdown (age variable)
                                            onChange={handleMobInputChange('companySize')} // Function to handle changes in the dropdown
                                            label={<span>Company Size<span style={redAsteriskStyle}>*</span></span>} // Label for the select input
                                        >
                                            <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Large">Large</MenuItem>
                                            <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Medium">Medium</MenuItem>
                                            <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Small">Small</MenuItem>
                                            <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Start Up">Start Up</MenuItem>




                                        </Select>
                                    </FormControl>








                                    <div style={{ position: 'relative' }}>
                                        <TextField
                                            style={{ width: `100%` }}
                                            id="preferred-call-time"
                                            label={<span>Preferred Call Time <span style={redAsteriskStyle}>*</span></span>}
                                            variant="standard"
                                            inputProps={{ style: textFieldStyle, readOnly: true }}
                                            InputLabelProps={{
                                                style: { color: 'white' }, // Color for label
                                            }}
                                            onClick={handleTextFieldClickmob} // Handle click event
                                            sx={{
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: '#DDB96E', // Change focus underline color to black
                                                },
                                            }}
                                            onChange={handleMobInputChange('preferredCallTime')}
                                            value={inputValue}
                                        />
                                        {showModalmob && <MobModal onClose={() => setShowModalmob(false)} onSubmit={handleMobSelectedDateTime} />} {/* Render modal if showModal is true */}


                                    </div>




                                    <ReCAPTCHA
                                        sitekey="6LfWlv8pAAAAAIXIm5judvIw4fuWoObkZjsLfEbl"
                                        onChange={handleRecaptchaChange}


                                    />

                                    <div >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={checked}
                                                    onChange={handleCheckboxChange}
                                                    name="checkedItem"
                                                    color="success"
                                                    sx={{
                                                        color: 'white',  // Set the checkbox color to white
                                                        '&.Mui-checked': {
                                                            color: 'green',  // Set the checked color to green
                                                        },
                                                        '& .MuiIconButton-root': {
                                                            borderColor: 'white',  // Set the border color of the icon button to white
                                                        },
                                                    }}
                                                />

                                            }
                                            label={<Typography sx={{ margin: '0 !important' }} variant="body1">I agree to the terms and conditions</Typography>}
                                        />
                                    </div>

                                    <button id="contactFormButtomMob"
                                        disabled={!isVerified}
                                        onClick={handleMobSendButtonClick}
                                        className={styles.sendButton} style={{ height: `3vw`, background: '#DDB96E', borderRadius: 5, color: '#000', fontSize: '1.4rem' }}>Schedule Consultation </button>
                                    {showAlerts && emailSent && (
                                        <div className="ValidalertMessage" style={{ height: 60, paddingLeft: 10, paddingRight: 0, background: 'rgba(0, 0, 0, 0.80)', borderRadius: 5, border: '1px #7FBE8D solid', justifyContent: 'flex-start', alignItems: 'center', gap: 23, display: 'inline-flex' }}>
                                            <div className="Frame427320152" style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex' }}>
                                                <img style={{ width: '22px' }} src="/valid.svg" alt="" />
                                                <div className="ThankYouWeWillContactYouVerySoon" style={{ color: '#7FBE8D', fontSize: '4.4vw', fontFamily: 'Quicksand', fontWeight: '400', wordWrap: 'break-word' }}>Thanks, We will contact you soon.</div>
                                            </div>
                                        </div>
                                    )}
                                    {showAlerts && !emailSent && (
                                        <div className="WrongalertMessage" style={{ height: 60, paddingLeft: 10, paddingRight: 16, background: 'rgba(0, 0, 0, 0.80)', borderRadius: 5, border: '1px #D75353 solid', justifyContent: 'flex-start', alignItems: 'center', gap: 23, display: 'inline-flex' }}>
                                            <div className="Frame427320152" style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex' }}>
                                                <img style={{ width: '22px' }} src="/notvalid.svg" alt="" />
                                                <div className="MessageNotSentPleaseCheckYourConnectivity" style={{ color: '#D75353', fontSize: '4.4vw', fontFamily: 'Quicksand', fontWeight: '400', wordWrap: 'break-word' }}>{errorMessage}</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
                <BottomButton></BottomButton>
            </div>
            <div className={styles.pageContainer}>
                <div className={styles.overlay} >
                    <div className={styles.TopframeCont}>
                        {/* <div className={styles.topFrame1}></div> */}
                        <div className={styles.topFrame}></div>
                    </div>
                    <div style={{ height: '100% ', overflowY: 'auto', display: 'flex', flexDirection: `column`, width: `100%` }}>
                        <div className={styles.subScroll} >
                            <div className={styles.question}>
                                <div className={styles.bgImageCont}>
                                    <img src="./Q mark.png" alt="" />
                                </div>
                                <div className={styles.headline}>
                                    <h1>FAQs</h1>
                                    <h2>Financial Solutions</h2>
                                </div>
                                <div className={styles.SubframesCont1}>
                                    <FAQFrame
                                        question="What are the key benefits of using your financial consulting services?"

                                        additionalText="The key benefits of utilizing our financial consulting services include personalized solutions aligned with your financial goals, expert guidance from seasoned professionals, and the assurance of financial peace of mind through strategic planning and optimization of resources."
                                    />
                                    <FAQFrame
                                        question="How do you ensure compliance with financial regulations and industry standards?"

                                        additionalText="To guarantee compliance with financial regulations and industry standards, we employ meticulous monitoring practices, staying abreast of evolving regulations, and implementing proactive measures to ensure our clients operate within the bounds of legal and industry requirements."
                                    />
                                    <FAQFrame
                                        question="How do you address financial challenges unique to our industry?"

                                        additionalText="In addressing financial challenges unique to your industry, we leverage our in-depth understanding to tailor solutions. "
                                    />
                                    <div className={styles.headline} style={{ marginTop: '3%' }}>

                                        <h2>Exporting & Trading</h2>
                                    </div>
                                    <div className={styles.SubframesCont1}>
                                        <FAQFrame
                                            question="Which regions or countries do you facilitate trades & exports to and from?"

                                            additionalText="Our company is committed to facilitating trade and exports globally. We have a widespread network and the capability to export our products to various regions and countries around the world."
                                        />
                                        <FAQFrame
                                            question="What shipping methods and logistics solutions do you offer?"

                                            additionalText="We provide a range of shipping methods and logistics solutions, Our services aim to offer flexibility, efficiency, and cost-effectiveness to meet diverse shipping needs."
                                        />
                                        <FAQFrame
                                            question="How do you handle customs and regulatory requirements for international trade?"

                                            additionalText="Our dedicated team is well-versed in global regulations, streamlining the customs clearance process and minimizing potential delays to facilitate seamless international trade transactions."
                                        />
                                    </div>
                                </div>
                            </div>
                            <div id="contactForm" style={{ display: 'flex', flexDirection: 'row', height: `57%`, marginTop: `5%` }}>
                                <div style={{ width: '50%', height: '100%', position: 'relative' }}>
                                    <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                                        <img style={{ width: '100%', height: '110%', zIndex: `-1`, position: 'absolute' }} src="/contact_bg.png" alt="" />

                                        <div className={styles.ContactLabel}>
                                            <div className={styles.ContactLabelCont}>
                                                <h1>
                                                    Get in touch,
                                                </h1>
                                                <h1>
                                                    Banknote here
                                                </h1>
                                                <h1>
                                                    to <span style={{ color: '#E4C78B' }}>Help your</span>
                                                </h1>
                                                <h1 style={{ color: '#E4C78B' }}>
                                                    Business!
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: `50%` }}>
                                    <div id="contactForm" className={styles.contactForm}>
                                        <div className={styles.underlinetextfield}>
                                            {/* <TextField
                                                id="company-name"
                                                label={<span>Company Name <span style={redAsteriskStyle}>*</span></span>}
                                                variant="standard"
                                                inputProps={{ style: textFieldStyle }}
                                                InputLabelProps={{
                                                    style: { color: 'white' }, // Color for label
                                                }}
                                                sx={{
                                                    '& .MuiInput-underline:after': {
                                                        borderBottomColor: '#DDB96E', // Change focus underline color to black
                                                    },
                                                }}
                                                value={formData.companyName}
                                                onChange={handleInputChange('companyName')}
                                            /> */}



                                            <TextField
                                                id="full-name"
                                                label={<span>Full Name <span style={redAsteriskStyle}>*</span></span>}
                                                variant="standard"
                                                inputProps={{ style: textFieldStyle }}
                                                InputLabelProps={{
                                                    style: { color: 'white' }, // Color for label
                                                }}
                                                sx={{
                                                    '& .MuiInput-underline:after': {
                                                        borderBottomColor: '#DDB96E', // Change focus underline color to black
                                                    },
                                                }}
                                                value={formData.fullName}
                                                onChange={handleInputChange('fullName')}
                                            />


                                            <TextField
                                                id="phone-number"
                                                label={<span>Phone Number <span style={redAsteriskStyle}>*</span></span>}
                                                variant="standard"
                                                inputProps={{ style: textFieldStyle }}
                                                InputLabelProps={{
                                                    style: { color: 'white' }, // Color for label
                                                }}
                                                sx={{
                                                    '& .MuiInput-underline:after': {
                                                        borderBottomColor: '#DDB96E', // Change focus underline color to black
                                                    },
                                                }}
                                                value={formData.phoneNumber}
                                                onChange={handleInputChange('phoneNumber')}
                                            />


                                            <FormControl variant="standard" sx={{
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: '#DDB96E', // Change focus underline color to white
                                                },
                                                '& .MuiInput-underline:before': {
                                                    borderBottomColor: 'rgba(255, 255, 255, 0.637)', // Change default underline color to white
                                                },
                                                '& .MuiInputBase-input': {
                                                    color: 'white', // Change font color to white
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: 'white', // Change label color to white
                                                },
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: 'white', // Change label color to white when focused
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    color: 'white', // Change the color of the arrow to white
                                                },
                                            }}>
                                                <InputLabel id="demo-simple-select-standard-label2">
                                                    {<span>Job Title <span style={redAsteriskStyle}>*</span></span>}
                                                </InputLabel>
                                                <Select
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                backgroundColor: '#262729', // Change the background color here
                                                                color: 'white',
                                                                fontFamily: 'Cairo Light'
                                                            }
                                                        }
                                                    }}
                                                    labelId="demo-simple-select-standard-label2"
                                                    id="demo-simple-select-standard2"
                                                    value={formData.jobTitle} // The value selected in the dropdown (age variable)
                                                    onChange={handleInputChange('jobTitle')} // Function to handle changes in the dropdown
                                                    label={<span>Your Job Title <span style={redAsteriskStyle}>*</span></span>} // Label for the select input
                                                >
                                                    <MenuItem value="Business Owner">Business Owner</MenuItem>
                                                    <MenuItem value="Marketing Manager">Marketing Manager</MenuItem>
                                                    <MenuItem value="Financial Manager">Financial Manager</MenuItem>
                                                    <MenuItem value="Export Manager">Export Manager</MenuItem>
                                                    <MenuItem value="Sales Manager">Sales Manager</MenuItem>
                                                </Select>
                                            </FormControl>



                                            <FormControl variant="standard" sx={{
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: '#DDB96E', // Change focus underline color to white
                                                },
                                                '& .MuiInput-underline:before': {
                                                    borderBottomColor: 'rgba(255, 255, 255, 0.637)', // Change default underline color to white
                                                },
                                                '& .MuiInputBase-input': {
                                                    color: 'white', // Change font color to white
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: 'white', // Change label color to white
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    color: 'white', // Change the color of the arrow to white
                                                },
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: 'white', // Change label color to white when focused
                                                },
                                            }}>
                                                <InputLabel id="demo-simple-select-standard-label">
                                                    <span>Country<span style={redAsteriskStyle}>*</span></span>
                                                </InputLabel>
                                                <Select
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                backgroundColor: '#262729', // Change the background color here
                                                                color: 'white',
                                                                fontFamily: 'Cairo Light',
                                                                maxHeight: 200, // Adjust the maximum height of the dropdown list
                                                            }
                                                        }
                                                    }}
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={formData.Country} // The value selected in the dropdown
                                                    onChange={handleInputChange('Country')} // Function to handle changes in the dropdown
                                                    label={<span>Country<span style={redAsteriskStyle}>*</span></span>} // Label for the select input
                                                >
                                                    {countriesList.map(country => (
                                                        <MenuItem
                                                            key={country.alpha2_code} // Use a unique key, such as alpha2_code
                                                            className={styles.MenuItem2}
                                                            style={{
                                                                fontFamily: 'Cairo Light', display: 'flex',
                                                                justifyContent: 'start'
                                                            }} // Align text to the left
                                                            value={country.english_name} // Set the value to the English name or any other identifier
                                                        >
                                                            {country.english_name} {/* Display the English name or any other representation */}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>


                                            <TextField
                                                id="email"
                                                label={<span>Email <span style={redAsteriskStyle}>*</span></span>}
                                                variant="standard"
                                                inputProps={{ style: textFieldStyle }}
                                                InputLabelProps={{
                                                    style: { color: 'white' }, // Color for label
                                                }}
                                                sx={{
                                                    '& .MuiInput-underline:after': {
                                                        borderBottomColor: '#DDB96E', // Change focus underline color to black
                                                    },
                                                }}
                                                value={formData.email}
                                                onChange={handleInputChange('email')}
                                            />
                                            <FormControl
                                                variant="standard"
                                                sx={{
                                                    '& .MuiInput-underline:after': {
                                                        borderBottomColor: '#DDB96E', // Change focus underline color to white
                                                    },
                                                    '& .MuiInput-underline:before': {
                                                        borderBottomColor: 'rgba(255, 255, 255, 0.637)', // Change default underline color to white
                                                    },
                                                    '& .MuiInputBase-input': {
                                                        color: 'white', // Change font color to white
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        color: 'white', // Change label color to white
                                                    },
                                                    '& .MuiInputLabel-root.Mui-focused': {
                                                        color: 'white', // Change label color to white when focused
                                                    },
                                                    '& .MuiSvgIcon-root': {
                                                        color: 'white', // Change the color of the arrow to white
                                                    },
                                                }}
                                            >
                                                <InputLabel id="service-type-label">
                                                    <span>Service Type <span style={{ color: 'red' }}>*</span></span>
                                                </InputLabel>
                                                {formData.Country === 'Egypt' ? (
                                                    <Select
                                                        className={styles.selectedItem2}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    backgroundColor: '#262729', // Change the background color here
                                                                    color: 'white',
                                                                    fontFamily: 'Cairo Light',
                                                                },
                                                            },
                                                        }}
                                                        labelId="service-type-label"
                                                        id="service-type"
                                                        value={formData.serviceType.split(' - ')[0]} // The value selected in the dropdown
                                                        onChange={handleInputChange('serviceType')} // Function to handle changes in the dropdown
                                                        label={<span>Service Type <span style={{ color: 'red' }}>*</span></span>} // Label for the select input
                                                        sx={{
                                                            "& .MuiSvgIcon-root": {
                                                                left: "unset",
                                                                right: "7px",
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Financial Consultations">
                                                            Financial Consulting
                                                        </MenuItem>
                                                        <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Local Supplies">
                                                            Local Supplies
                                                        </MenuItem>
                                                        <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Wholesale Trade">
                                                            Wholesale Trade
                                                        </MenuItem>
                                                        <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Export">
                                                            Export
                                                        </MenuItem>
                                                        <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Integrated Services">
                                                            Integrated Services
                                                        </MenuItem>
                                                    </Select>
                                                ) : (
                                                    <Select
                                                        className={styles.selectedItem2}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    backgroundColor: '#262729', // Change the background color here
                                                                    color: 'white',
                                                                    fontFamily: 'Cairo Light',
                                                                },
                                                            },
                                                        }}
                                                        labelId="service-type-label"
                                                        id="service-type"
                                                        value={formData.serviceType.split(' - ')[0]} // The value selected in the dropdown
                                                        onChange={handleInputChange('serviceType')} // Function to handle changes in the dropdown
                                                        label={<span>Service Type <span style={{ color: 'red' }}>*</span></span>} // Label for the select input
                                                        sx={{
                                                            "& .MuiSvgIcon-root": {
                                                                left: "unset",
                                                                right: "7px",
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Financial Consultations">
                                                            Financial Consulting
                                                        </MenuItem>
                                                        <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Local Supplies">
                                                            Local Supplies
                                                        </MenuItem>
                                                        <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Wholesale Trade">
                                                            Wholesale Trade
                                                        </MenuItem>
                                                        <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Import from Egypt">
                                                            Import from Egypt
                                                        </MenuItem>
                                                        <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Integrated Services">
                                                            Integrated Services
                                                        </MenuItem>
                                                    </Select>
                                                )}
                                            </FormControl>

                                            {showIntegratedServices && (
                                                <FormControl
                                                    variant="standard"
                                                    sx={{
                                                        '& .MuiInput-underline:after': {
                                                            borderBottomColor: '#DDB96E', // Change focus underline color to white
                                                        },
                                                        '& .MuiInput-underline:before': {
                                                            borderBottomColor: 'rgba(255, 255, 255, 0.637)', // Change default underline color to white
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            color: 'white', // Change font color to white
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: 'white', // Change label color to white
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            color: 'white', // Change the color of the arrow to white
                                                        },
                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                            color: 'white', // Change label color to white when focused
                                                        },
                                                    }}
                                                >
                                                    <InputLabel id="integrated-service-label">
                                                        <span>Integrated Service<span style={{ color: 'red' }}>*</span></span>
                                                    </InputLabel>
                                                    <Select
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    backgroundColor: '#262729', // Change the background color here
                                                                    color: 'white',
                                                                    fontFamily: 'Cairo Light'
                                                                }
                                                            }
                                                        }}
                                                        labelId="integrated-service-label"
                                                        id="integrated-service"
                                                        value={formData.serviceType.split(' - ')[1] || ''} // The value selected in the dropdown
                                                        onChange={handleIntegratedServiceChange} // Function to handle changes in the dropdown
                                                        label={<span>Integrated Service <span style={{ color: 'red' }}>*</span></span>} // Label for the select input
                                                    >
                                                        <MenuItem value="Maintenance">Maintenance</MenuItem>
                                                        <MenuItem value="Contracting">Contracting</MenuItem>
                                                        <MenuItem value="Transportation">Transportation</MenuItem>
                                                        <MenuItem value="Web Design">Web Design</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            )}

                                            <TextField
                                                id="your-sector"
                                                label={<span>Your Sector <span style={redAsteriskStyle}>*</span></span>}
                                                variant="standard"
                                                inputProps={{ style: textFieldStyle }}
                                                InputLabelProps={{
                                                    style: { color: 'white' }, // Color for label
                                                }}
                                                sx={{
                                                    '& .MuiInput-underline:after': {
                                                        borderBottomColor: '#DDB96E', // Change focus underline color to black
                                                    },
                                                }}
                                                value={formData.yourSector}
                                                onChange={handleInputChange('yourSector')}
                                            />






                                            <FormControl variant="standard" sx={{
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: '#DDB96E', // Change focus underline color to white
                                                },
                                                '& .MuiInput-underline:before': {
                                                    borderBottomColor: 'rgba(255, 255, 255, 0.637)', // Change default underline color to white
                                                },
                                                '& .MuiInputBase-input': {
                                                    color: 'white', // Change font color to white
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: 'white', // Change label color to white
                                                },
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: 'white', // Change label color to white when focused
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    color: 'white', // Change the color of the arrow to white
                                                },
                                            }}>
                                                <InputLabel id="demo-simple-select-standard-label2">
                                                    {<span>Company Size<span style={redAsteriskStyle}>*</span></span>}
                                                </InputLabel>
                                                <Select
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                backgroundColor: '#262729', // Change the background color here
                                                                color: 'white',
                                                                fontFamily: 'Cairo Light'
                                                            }
                                                        }
                                                    }}
                                                    labelId="demo-simple-select-standard-label2"
                                                    id="demo-simple-select-standard2"
                                                    value={formData.companySize} // The value selected in the dropdown (age variable)
                                                    onChange={handleInputChange('companySize')} // Function to handle changes in the dropdown
                                                    label={<span>Your Job Title <span style={redAsteriskStyle}>*</span></span>} // Label for the select input
                                                >
                                                    <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Large">Large</MenuItem>
                                                    <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Medium">Medium</MenuItem>
                                                    <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Small">Small</MenuItem>
                                                    <MenuItem className={styles.MenuItem2} style={{ fontFamily: 'Cairo Light', display: 'flex', justifyContent: 'start' }} value="Start Up">Start Up</MenuItem>


                                                </Select>
                                            </FormControl>





                                            <div style={{ position: 'relative' }}>
                                                <TextField
                                                    style={{ width: `100%` }}
                                                    id="preferred-call-time-web"
                                                    label={<span>Preferred Call Time <span style={redAsteriskStyle}>*</span></span>}
                                                    variant="standard"
                                                    inputProps={{ style: textFieldStyle, readOnly: true }}
                                                    InputLabelProps={{
                                                        style: { color: 'white' }, // Color for label
                                                    }}
                                                    onClick={handleTextFieldClick} // Handle click event
                                                    sx={{
                                                        '& .MuiInput-underline:after': {
                                                            borderBottomColor: '#DDB96E', // Change focus underline color to black
                                                        },
                                                    }}

                                                    onChange={handleInputChange('preferredCallTime')}
                                                    value={inputValue} // Bind the value of the input field to the state
                                                />
                                                {showModal && <Modal onClose={() => setShowModal(false)} onSubmit={handleSelectedDateTime} />} {/* Render modal if showModal is true */}
                                                <img
                                                    style={{
                                                        position: 'absolute',
                                                        right: 0,
                                                        width: '5.5%',
                                                        top: '5.5px',
                                                        transition: 'transform 0.2s ease-in-out', // Add transition for a smoother effect
                                                    }}
                                                    src="/info.svg"
                                                    alt=""
                                                    onMouseOver={(e) => {
                                                        e.target.style.transform = 'scale(1.1)';
                                                        setIsHovered(true);
                                                    }} // Add hover effect
                                                    onMouseOut={(e) => {
                                                        e.target.style.transform = 'scale(1)';
                                                        setIsHovered(false);
                                                    }} // Reset on hover out
                                                />
                                                {isHovered && (
                                                    <div style={{ position: 'relative' }}>
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                top: '-9px',
                                                                right: '0px', // Adjust the right property to move the frame to the right

                                                                width: 0,
                                                                height: 0,
                                                                borderLeft: '10px solid transparent',
                                                                borderRight: '10px solid transparent',
                                                                borderBottom: '10px solid #fff', // Adjust the color as needed
                                                                zIndex: '2',
                                                            }}
                                                        ></div>
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                top: '100%',
                                                                right: '-25px', // Adjust the right property to move the frame to the right
                                                                padding: '10px',
                                                                background: '#fff',
                                                                border: '1px solid #ddd',
                                                                borderRadius: '4px',
                                                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                                zIndex: '1',
                                                            }}
                                                        >
                                                            <p className={styles.msgInfo}>When do you want us to call you?</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            <ReCAPTCHA
                                                sitekey="6LfWlv8pAAAAAIXIm5judvIw4fuWoObkZjsLfEbl"
                                                onChange={handleRecaptchaChange}

                                            />
                                            <div>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checked}
                                                            onChange={handleCheckboxChange}
                                                            name="checkedItem"
                                                            color="success"
                                                            sx={{
                                                                color: 'white',  // Set the checkbox color to white
                                                                '&.Mui-checked': {
                                                                    color: 'green',  // Set the checked color to green
                                                                },
                                                                '& .MuiIconButton-root': {
                                                                    borderColor: 'white',  // Set the border color of the icon button to white
                                                                },
                                                            }}
                                                        />

                                                    }
                                                    label={<Typography sx={{ margin: '0 !important' }} variant="body1">I agree to the terms and conditions</Typography>}
                                                />
                                            </div>
                                            <button
                                                className={styles.sendButton}
                                                style={{ height: '3vw', background: '#DDB96E', borderRadius: 5 }}
                                                onClick={handleSendButtonClick}


                                                disabled={!isVerified}

                                            >
                                                {isLoading ? (
                                                    <div className={styles.sendButton} style={{ height: '3vw', background: '#DDB96E', borderRadius: 5 }}>Loading..
                                                        {/* <div className={styles.spinner} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', height: '3vw' }}></div> */}
                                                    </div>
                                                ) : (
                                                    'Send'
                                                )}
                                            </button>
                                            {showAlerts && emailSent && (
                                                <div className="ValidalertMessage" style={{ height: 52, paddingLeft: 16, paddingRight: 16, background: 'rgba(0, 0, 0, 0.80)', borderRadius: 5, border: '1px #7FBE8D solid', justifyContent: 'flex-start', alignItems: 'center', gap: 23, display: 'inline-flex' }}>
                                                    <div className="Frame427320152" style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex' }}>
                                                        <img src="/valid.svg" alt="" />
                                                        <div className="ThankYouWeWillContactYouVerySoon" style={{ color: '#7FBE8D', fontSize: '1vw', fontFamily: 'Quicksand', fontWeight: '400', wordWrap: 'break-word' }}>Thank you, We will contact you very soon.</div>
                                                    </div>
                                                </div>
                                            )}
                                            {showAlerts && !emailSent && (
                                                <div className="WrongalertMessage" style={{ height: 52, paddingLeft: 16, paddingRight: 16, background: 'rgba(0, 0, 0, 0.80)', borderRadius: 5, border: '1px #D75353 solid', justifyContent: 'flex-start', alignItems: 'center', gap: 23, display: 'inline-flex' }}>
                                                    <div className="Frame427320152" style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex' }}>
                                                        <img src="/notvalid.svg" alt="" />
                                                        <div className="MessageNotSentPleaseCheckYourConnectivity" style={{ color: '#D75353', fontSize: '1vw', fontFamily: 'Quicksand', fontWeight: '400', wordWrap: 'break-word' }}>{errorMessage}</div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className={styles.footer}>
                                <div style={{ margin: `0`, width: `70%` }} className="container">
                                    <div style={{ width: `115%` }} className="row mb-4">
                                        <div className="col-md-6">

                                            <div className={styles.footerSubCont}   >
                                                <img src="/footericons/location.svg" alt="" />

                                                <h1>106 Nile, 5th Floor, Dokki, Giza, Egypt</h1>
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                            <div className={styles.footerSubCont}   >
                                                <img src="/footericons/clock.svg" alt="" />

                                                <h1>Saturday - Thursday 08:00 am - 06:00 pm</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: `115%` }} className="row mb-4">
                                        <div className="col-md-6">

                                            <div className={styles.footerSubCont}   >
                                                <img src="/footericons/mail.svg" alt="" />

                                                <h1>info@banknoteconsult.com</h1>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className={styles.footerSubCont}   >
                                                <img src="/footericons/phone.svg" alt="" />

                                                <h1>(+20) 1000046940 or (+20) 1000558282</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: `115%` }} className="row mb-4">
                                        {/* <div className="col-md-6">
                                            <div className={styles.footerSubCont}   >
                                                <img src="/footericons/people.svg" alt="" />

                                                <h1>Careers</h1>
                                            </div>
                                        </div> */}
                                        <div className="col-md-6">
                                            <div className={styles.footerSubCont}   >
                                                <img src="/footericons/telephone.svg" alt="" />

                                                <h1>0233365177</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div >);
}

export default FAQs;
