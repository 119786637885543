import React from 'react';
import styles from './Services.module.css'; // Adjust the import based on your file structure

const Sliderservices = ({ data }) => {
    const currentFrame = data;


    return (
        <div className={styles.sliderCont}>
            <div className={styles.sliderContainer}>
                <div className={styles.sliderContent}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '80%' }}>

                        <div className={styles.sliderText}>
                            <h2 className={styles.head1}>{currentFrame.head1}</h2>
                            <h2 className={styles.head2}>{currentFrame.head2}</h2>
                            <div className={styles.disCont}><p>{currentFrame.description}</p></div>

                        </div>
                        <div className={styles.sliderImage}>
                            <img src={currentFrame.imageSrc} alt="Slider Image" />
                        </div>
                    </div>

                </div>
                { }
                {/* Frames container */}
                <div className={styles.framesContainer}>
                    {/* Frames in rows of three */}
                    <div className={styles.frameRow}>
                        {/* Frame 1 */}

                        {currentFrame.frame1 !== null && (
                            <div className={styles.shapecontainer}>
                                <div className={styles.numbline}>
                                    <div className={styles.numberingshape}>
                                        <span className={styles.number}>1</span>
                                    </div>
                                    <hr className={styles.separator} />
                                </div>
                                <div className={styles.frame}>
                                    <h3>{currentFrame.frame1}</h3>
                                </div>
                            </div>
                        )}

                        {/* Frame 2 */}
                        {currentFrame.frame2 !== null && (
                            <div className={styles.shapecontainer}>
                                <div className={styles.numbline}>
                                    <div className={styles.numberingshape}>
                                        <span className={styles.number}>2</span>
                                    </div>
                                    <hr className={styles.separator} />
                                </div>
                                <div className={styles.frame}>
                                    <h3>{currentFrame.frame2}</h3>
                                </div>
                            </div>
                        )}

                        {/* Frame 3 */}
                        {currentFrame.frame3 !== null && (
                            <div className={styles.shapecontainer}>
                                <div className={styles.numbline}>
                                    <div className={styles.numberingshape}>
                                        <span className={styles.number}>3</span>
                                    </div>
                                    <hr className={styles.separator} />
                                </div>
                                <div className={styles.frame}>
                                    <h3>{currentFrame.frame3}</h3>
                                </div>
                            </div>
                        )}
                    </div>


                    <div className={styles.frameRow2}>
                        {/* Frame 4 */}
                        {currentFrame.frame4 !== null && (
                            <div className={styles.shapecontainer}>
                                <div className={styles.numbline}>
                                    <div className={styles.numberingshape}>
                                        <span className={styles.number}>4</span>
                                    </div>
                                    <hr className={styles.separator} />
                                </div>
                                <div className={styles.frame}>
                                    <h3>{currentFrame.frame4}</h3>
                                </div>
                            </div>
                        )}
                        {/* Frame 5 */}
                        {currentFrame.frame5 !== null && (
                            <div className={styles.shapecontainer}>
                                <div className={styles.numbline}>
                                    <div className={styles.numberingshape}>
                                        <span className={styles.number}>5</span>
                                    </div>
                                    <hr className={styles.separator} />
                                </div>
                                <div className={styles.frame}>
                                    <h3>{currentFrame.frame5}</h3>
                                </div>
                            </div>
                        )}
                        {/* Frame 6 */}
                        {currentFrame.frame6 !== null && (

                            <div className={styles.shapecontainer}>
                                <div className={styles.numbline}>
                                    <div className={styles.numberingshape}>
                                        <span className={styles.number}>6</span>
                                    </div>
                                    <hr className={styles.separator} />
                                </div>
                                <div className={styles.frame}>
                                    <h3>{currentFrame.frame6}</h3>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sliderservices;
